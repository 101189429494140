import React from "react";
import "./style.scss";
import Button from "../../../../../button/Button";
import { getClotureDossierArticle } from "../../../../../../../services/articles_api";

/**
 * Téléchargement d'une liste de données vierge
 * @param rfaContrat le rfa contrat concerné
 * @param hide la fonction de fermeture de modal
 */
const ARTclotureCard = ({
  hide,
  rfaContrat,
  refreshArticles,
  toggleNotification,
}) => {
  const handleValidation = () => {
    getClotureDossierArticle(rfaContrat.con_code)
      .then(() => {})
      .catch((error) => {
        console.error("Erreur lors de la clôture du dossier:", error);
      });
    refreshArticles();
    hide();
    toggleNotification();
  };

  return (
    <>
      <div className="downlist-card">
        <p className="downlist-card__body">
          Une fois valider vous ne pourrez pas revenir en arrière.
        </p>
        <div className="downlist-card__action">
          <Button
            libelle="Valider"
            color="excel-btn"
            onClick={handleValidation} // Modification ici
          />
          <Button libelle="Annuler" color="color-neutral" onClick={hide} />
        </div>
      </div>
    </>
  );
};

export default ARTclotureCard;
