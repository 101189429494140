import React from 'react';
import './style.scss';
import useModal from '../../../../../../hooks/useModal';
import Modal from '../../../Modal';
import MagIcon from '../../../../icons/MagIcon';
import DECmagasinForm from '../../../../forms/_RFA/_Declaration/DECmagasinForm';
import RFAmagInfoCard from '../../../../cards/_RFA/RFAmagInfoCard';
import ModifyButton from '../../../../button/ModifyButton';

const DECdeclarationModal = ({
  magasin,
  refreshData,
  rfaContrat,
}) => {
  const modalState = useModal();
  // const libelleButton = () => {
  //   return !rfaContrat.statut_declaration ? 'Saisir' : 'Détails';
  // };
  if (!magasin) return null;
  return (
    <>
      <ModifyButton onClick={() => modalState.toggleModal()} />
      <Modal
        modalState={modalState}
        title={magasin.mag_nom}
        color="primary-modal"
        icon={<MagIcon />}
      >
        <div className="declaration-modal-wrapper">
          <div className="declaration-modal">
            <section className="declaration-modal__details">
              <div className="details-declaration">
                <RFAmagInfoCard magasin={magasin} />
              </div>
            </section>
            <section className="declaration-modal__form">
              <DECmagasinForm
                refreshData={refreshData}
                magasin={magasin}
                hide={modalState.toggleModal}
                rfaContrat={rfaContrat}
              />
            </section>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DECdeclarationModal;
