import React from "react";
import "./style.scss";
import { useOutletContext } from "react-router-dom";
import ADMrappelDeclarationAllModal from "../../../components/ui/modals/_Administration/ADMrappelDeclarationAllModal";
import ADMcontratsRFATable from "../../../components/ui/tables/_Administration/ADMarticle/ADMcontratsRFATable";

const DashboardArticle = () => {
  const { contratArticle } = useOutletContext();
  const { refetchContratArticle } = useOutletContext();

  return (
    <>
      <div className="dashboard-rfa-screen">
        <div className="dashboard-rfa">
          <div className="dashboard-rfa__head">
            <h3 className="title">Tarifications des Articles</h3>
            {/* <div className="action">
              <ADMrappelDeclarationAllModal />
            </div> */}
          </div>
          <div className="dashboard-rfa__table">
            <ADMcontratsRFATable
              data={contratArticle}
              refreshData={refetchContratArticle}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardArticle;
