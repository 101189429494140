import React from 'react';
import useModal from '../../../../../hooks/useModal';
import { toggleValidationLitige } from '../../../../../services/administration_apis';
import Button from '../../../button/Button';
import ValidationModal from '../../ValidationModal';

const ADMvalidationLitigeModal = ({ bordereau, refetchData }) => {
  const modalState = useModal();
  const position = 1; // position du bordereau lorsqu'il n'est pas valide

  /**
   * Envoie un mail contentant le lien du portail.
   */
  const handleToggle = async () => {
    try {
      await toggleValidationLitige(bordereau.lev_numero);
      refetchData();
    } catch (error) {
      console.log(error);
    }
  };

  if (!bordereau) return null;
  return (
    <>
      {bordereau.lev_position === position ? (
        <Button
          libelle="Clôturer"
          color="color-tertiaire-variant"
          onClick={() => modalState.toggleModal()}
        />
      ) : (
        <Button
          libelle="Ouvrir"
          color="color-tertiaire-variant"
          onClick={() => modalState.toggleModal()}
        />
      )}

      <ValidationModal
        modalState={modalState}
        onConfirmation={() => handleToggle()}
        title={`${
          bordereau.lev_position === position
            ? 'Validation'
            : 'Ouverture'
        } de litiges`}
      >
        <>
          {bordereau.lev_position === position ? (
            <>Valider les litiges pour </>
          ) : (
            <>Ouvrir à nouveau les litiges pour </>
          )}
          {bordereau.contrat.con_nom}
        </>
      </ValidationModal>
    </>
  );
};

export default ADMvalidationLitigeModal;
