import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import AdminLayout from "../../components/layouts/AdminLayout";
import LoaderApp from "../../components/ui/loaders/LoaderApp";
import { getContratsRFA } from "../../services/contratsRFA_apis";
import { getFournisseurs } from "../../services/user_apis";
import { getContratsDashBoard } from "../../services/articles_api";

/**
 * Page racine pour l'administration pour la mise en forme et la récupérations de données
 */
const Administration = () => {
  const [loading, setLoading] = useState(true); // Indicate the call has not finished. Default to true
  const [contratsRFA, setContratsRFA] = useState({});
  const [fournisseurs, setFournisseurs] = useState({});
  const [contratArticle, setContratArticle] = useState({});

  /**
   * Récupère les  contrats rfa
   */
  const fetchContratsRFA = React.useCallback(async () => {
    try {
      const data = await getContratsRFA();
      // console.log(data);
      setContratsRFA(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  /**
   * Récupère les fournisseurs
   */
  const fetchFournisseurs = React.useCallback(async () => {
    try {
      const data = await getFournisseurs();
      setFournisseurs(data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  /**
   * Récupère les contrats article
   */
  const fetchContratArticle = React.useCallback(async () => {
    try {
      const data = await getContratsDashBoard();
      setContratArticle(data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  /**
   * Rafraichis les contrats rfa
   */
  const refetchContratsRFA = () => {
    const refetch = async () => {
      await fetchContratsRFA();
    };
    refetch();
  };

  /**
   * Rafraichis les fournisseurs
   */
  const refetchFournisseurs = () => {
    const refetch = async () => {
      await fetchFournisseurs();
    };
    refetch();
  };

  /**
   * Rafraichis les contrats article
   */
  const refetchContratArticle = () => {
    const refetch = async () => {
      await fetchContratArticle();
    };
    refetch();
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchContratsRFA();
        await fetchFournisseurs();
        await fetchContratArticle();
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [fetchContratsRFA, fetchFournisseurs, fetchContratArticle]);

  if (loading)
    return (
      <AdminLayout>
        <LoaderApp />
      </AdminLayout>
    );

  return (
    <>
      <AdminLayout>
        <Outlet
          context={{
            contratsRFA,
            refetchContratsRFA,
            fournisseurs,
            refetchFournisseurs,
            contratArticle,
            refetchContratArticle,
          }}
        />
      </AdminLayout>
    </>
  );
};

export default Administration;
