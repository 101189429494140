import { useState } from 'react';

/**
 * Hook pour l'utilisation des notifications
 */
const useNotification = () => {
  const [isShowingNot, setIsShowingNot] = useState(false);

  const toggleNotification = () => {
    setIsShowingNot(!isShowingNot);
  };

  return {
    isShowingNot,
    toggleNotification,
  };
};

export default useNotification;
