import { DOMAIN } from "../config/env";
import api from './apiClient';

export async function getContratsDashBoard () {
  let contratsRFA = {};
  await api
    .get(`${DOMAIN.URL_API}contrats-dashBoard`)
    .then(async (res) => {
      contratsRFA = await res.data;
    });
  return contratsRFA;
}
export async function getArticlesByContrat (con_code) {
  let bordereaux = {};
  await api
    .get(`${DOMAIN.URL_API}articles/${con_code}`)
    .then((res) => {
      bordereaux = res.data;
    });
  return bordereaux;
}
export async function getClotureDossierArticle (con_code) {
  let bordereaux = {};
  await api
    .get(`${DOMAIN.URL_API}cloture-dossier-article/${con_code}`)
    .then((res) => {
      bordereaux = res.data;
    });
  return bordereaux;
}

export async function toggleClotureArticle (con_code) {
  const data = {
    con_code: con_code,
  };

  await api.put(
    DOMAIN.URL_API + "toggle-dossier",
    { ...data },
  );
}
export async function rappelArticle (con_code) {
  let bordereaux = {};
  await api
    .get(`${DOMAIN.URL_API}rappel-cloture-article/${con_code}`)
    .then((res) => {
      bordereaux = res.data;
    });
  return bordereaux;
}
/**
 * Import les déclarations de rfa
 * @param {*} data
 * @param {*} con_code
 */
export async function importArticle (file, con_code) {
  let total = 0; // Total importé
  let errors = { lignes: [], nb_lignes: 0 }; // Erreurs lors de l'importation

  const data = {
    importfile: file,
    con_code: con_code,
  };
  await api
    .post(
      DOMAIN.URL_API + "import-article",
      { ...data },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
      total = res.data["sum"];
    })
    .catch((err) => {
      console.log(err);
      const errs = err.response.data["errors"];
      errors = {
        ...errors,
        lignes: errs.lignes,
        nb_lignes: errs.nb_lignes,
      };
      total = err.response.data["sum"];
    });
  return { total: total, errors: errors };
}
