import React from 'react';
import ReactDOM from 'react-dom';
import ArrowSlideToLeft from '../../icons/ArrowSlideToLeft';
import ArrowSlideToRight from '../../icons/ArrowSlideToRight';
import './style.scss';

const Drawer = ({ drawerStates, title, children, left }) => {
  if (!drawerStates.isOpen) return null;
  return ReactDOM.createPortal(
    <>
      <div className="drawer-overlay ">
        <div
          className={`drawer ${
            left
              ? `${drawerStates.animation.left} left-side`
              : drawerStates.animation.right
          }`}
        >
          <div className="drawer-header">
            <button
              className="drawer-close"
              onClick={drawerStates.closeDrawer}
            >
              <span
                className={`arrow-slide-icon ${
                  left ? 'right-side' : 'left-side'
                }`}
              >
                {left ? <ArrowSlideToLeft /> : <ArrowSlideToRight />}
              </span>
            </button>
            <h3> {title}</h3>
          </div>
          <div className="drawer-content">{children}</div>
        </div>
      </div>
    </>,
    document.body
  );
};

export default Drawer;
