import React from "react";
import { formatPrix } from "../../../../../../../utils/format";
import AlertModal from "../../../../AlertModal";
import "./style.scss";

const AlertImportModal = ({ errors, total, rfaContrat, ...props }) => {
  return (
    <AlertModal {...props}>
      <div className="alert-import-modal">
        <h3 className="title">Importé :</h3>
        {!!errors.nb_lignes && (
          <>
            <span className="forward">
              Toutes les lignes n'ont pas été importées.
              <br />
              Vérifier la ou les lignes suivantes&nbsp;:&nbsp;
            </span>
            <ul>
              {errors.lignes.map((error, index) => (
                <li key={index}>
                  {error.champ} - {error.erreur}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </AlertModal>
  );
};

export default AlertImportModal;
