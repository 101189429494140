import React, { useCallback, useState } from "react";
import "./style.scss";
import SelectedMagCell from "./SelectedMagCell";
import SelectedMagHeader from "./SelectedMagHeader";
import { unSelectallDeclarationMagasinRfa } from "../../../../../../services/apis";
import { formatPrix } from "../../../../../../utils/format";
import Table from "../../../Table";
import DECdeclarationModal from "../../../../modals/_RFA/_Declaration/DECdeclarationModal";
import {formatDate} from "../../../../../../utils/format";


const DECmagasinTable = ({
  data,
  setData,
  refreshData,
  magasins,
  rfaContrat,
}) => {
  const [checkOnly, setCheckOnly] = useState(false); // L'indicateur du filtre checkOnly

  // Gere l'affichage des colonnes en fonction du rfa contrat
  const hiddenColumns = React.useMemo(
    () => [
      "codepostal",
      "mag_siret",
      !rfaContrat.regles.rce_libelle_3 && "cm_ca_3",
      !rfaContrat.regles.rce_libelle_4 && "cm_ca_4",
      !rfaContrat.regles.rce_libelle_5 && "cm_ca_5",
      !rfaContrat.regles.rce_libelle_6 && "cm_ca_6",
      !rfaContrat.regles.rce_libelle_7 && "cm_ca_7",
      !rfaContrat.regles.rce_libelle_8 && "cm_ca_8",
      !rfaContrat.regles.rce_libelle_9 && "cm_ca_9",

    ],
    [rfaContrat]
  );

  // Handle le toggle check only
  const handleCheckOnly = useCallback(
    (event) => {
      // Filter magasins
      if (magasins.length) {
        const isChecked = event.target.checked;
        let filtered = isChecked
          ? magasins.filter((magasin) => !!magasin.is_selected)
          : magasins;
        setCheckOnly(isChecked);
        setData(filtered);
      }
    },
    [magasins, setData]
  );

  // Décoche tous les magasins
  const handleUnCheckAll = useCallback(() => {
    const unCheckData = async () => {
      try {
        if (data.length) {
          await unSelectallDeclarationMagasinRfa(
            rfaContrat.con_code,
            rfaContrat.annee
          );
          data.map((magasin) => {
            return (magasin.is_selected = false);
          });
          await refreshData();
        }
      } catch (err) {
        console.log(err);
      }
    };
    unCheckData();
  }, [refreshData, data, rfaContrat.annee, rfaContrat.con_code]);

  // Defini les en-têtes et cellules des colonnes de la table
  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <SelectedMagHeader
            checkOnly={checkOnly}
            handleCheckOnly={handleCheckOnly}
          />
        ),
        sticky: "left",
        accessor: "is_selected",
        width: 0,
        Cell: (props) => <SelectedMagCell magasin={props.row.original} />,
      },

      {

        Header: "Nom",
        accessor: "mag_nom",
        width: 160,
      },
      {
        Header: "Enseigne",
        accessor: "ens_code",
        filter: "includes",
        width: 160,
        hideable: true,
      },
      {
        Header: "Ville",
        accessor: "adr_ville",
        filter: "includes",
        width: 160,
      },
      {
        Header: "Raison",
        accessor: "raisonsociale",
        filter: "includes",
        width: 180,
        hideable: true,
      },
      {
        Header: "Code postal",
        accessor: "codepostal",
        filter: "includes",
        hideable: true,
      },
      {
        Header: "Dépot(s)",
        accessor: "mag_depots",
        width: 200,
        hideable: true,
        // minWidth: 160,
      },
      {

        Header: "Siret",
        accessor: "mag_siret",
        filter: "includes",
        hideable: true,
      },
        {
            Header: 'Date entrée',
            accessor: 'dateentree',
            width:40,
            hideable: true,
            Cell: ({ value }) => formatDate(value),
        },
        {
            Header: 'Date sortie',
            accessor: 'datesortie',
            width:40,
            hideable: true,
            Cell: ({ value }) => formatDate(value),
        },
      {
        Header: `${rfaContrat.regles.rce_libelle_2}`,
        accessor: "cm_ca_remonte",
        currency: true,
        textAlign: "right",
        width: 150,

      },
      {
        Header: `${rfaContrat.regles.rce_libelle_1}`,
        accessor: "cm_ca_reel",
        currency: true,
        textAlign: "right",
        width: 150,

      },
      {
        Header: `${rfaContrat.regles.rce_libelle_3}`,
        accessor: "cm_ca_3",
        currency: true,
        textAlign: "right",
        width: 160,
      },
      {
        Header: `${rfaContrat.regles.rce_libelle_4}`,
        accessor: "cm_ca_4",
        currency: true,
        textAlign: "right",
        width: 160,
      },
      {
        Header: `${rfaContrat.regles.rce_libelle_5}`,
        accessor: "cm_ca_5",
        currency: true,
        textAlign: "right",
        width: 160,
      },
      {
        Header: `${rfaContrat.regles.rce_libelle_6}`,
        accessor: "cm_ca_6",
        currency: true,
        textAlign: "right",
        width: 100,
      },
      {
        Header: `${rfaContrat.regles.rce_libelle_7}`,
        accessor: "cm_ca_7",
        currency: true,
        textAlign: "right",
        width: 160,
      },
      {
        Header: `${rfaContrat.regles.rce_libelle_8}`,
        accessor: "cm_ca_8",
        currency: true,
        textAlign: "right",
        width: 160,
      },
      {
        Header: `${rfaContrat.regles.rce_libell_9}`,
        accessor: "cm_ca_9",
        currency: true,
        textAlign: "right",
        width: 160,
      },
      {
        Header: "",
        sticky: "right",
        accessor: "mag_code",
        width: 10,
        filter: "equals",
        Cell: (props) => (
          <div className="center-cell">
            <DECdeclarationModal
              magasin={props.row.original}
              refreshData={refreshData}
              rfaContrat={rfaContrat}
            />
          </div>
        ),
      },
    ],
    [checkOnly, handleCheckOnly, refreshData, rfaContrat]
  );

  return (
    <>
      <div className="declaration-table-overlay">
        <button
          type="button"
          className="uncheck-all-btn"
          onClick={() => handleUnCheckAll()}
        >
          [Décocher tous les magasins]
        </button>
        <div className="declaration-table">
          <Table
            data={data}
            columns={columns}
            hiddenColumns={hiddenColumns}
            filter
            pagination
            search={{
              placeholder: "Nom, SIRET, enseigne, code postal, ...",
            }}
            footer={
              <div className="pagination__info">
                <span>
                  {rfaContrat.regles.rce_libelle_2}&nbsp;:&nbsp;
                  {formatPrix(rfaContrat.ca.cm_ca_remonte)}
                </span>
                <span>
                  {rfaContrat.regles.rce_libelle_1}&nbsp;:&nbsp;
                  {formatPrix(rfaContrat.ca.cm_ca_reel)}
                </span>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default DECmagasinTable;
