import React, { useState } from 'react';
import { formatPrix } from '../../../../../utils/format';
import './style.scss';

const RFAinput = ({
  label,
  title,
  onChange,
  value,
  error,
  currency,
  ...rest
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [formattedValue, setFormattedValue] = useState(value);

  React.useEffect(() => {
    let val =
      (value === 0 || value) && currency && !isEditing
        ? formatPrix(value)
        : value;
    setFormattedValue(val);
  }, [currency, isEditing, value]);

  const handleChange = (event) => {
    let val = event.target.value;
    // Supprime les espaces et remplace les "," par des  "."
    if (currency) {
      val = val.replace(/\s/g, '');
      val = val.replace(/,/g, '.');
    }
    event.target.value = val;
    onChange(event);
  };
  const onFocus = () => {
    setIsEditing(true);
  };
  const onBlur = () => {
    setIsEditing(false);
  };

  return (
    <div className="rfa-input">
      <label title={title} className="label">
        {label}
      </label>
      <input
        // type={type}
        value={formattedValue}
        {...rest}
        className={` ${currency ? 'align-right' : ''}`}
        onChange={(event) => handleChange(event)}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <span className="error">{error ? <>{error}</> : null}</span>
    </div>
  );
};

export default RFAinput;
