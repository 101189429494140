import React from "react";
import "./style.scss";

import ARTdownloadListModal from "../../../ui/modals/_RFA/_Article/ARTdownloadListModal";
import ARTimportListModal from "../../../ui/modals/_RFA/_Article/ARTimportListModal";
import ARTclotureModal from "../../../ui/modals/_RFA/_Article/ARTclotureModal";

const ARTactionsList = ({
  refreshData,
  rfaContrat,
  refreshArticles,
  toggleNotification,
}) => {
  return (
    <div className={`declaration-actions`}>
      <div className="btn-list">
        {!rfaContrat.statut_declaration && (
          <>
            <ARTdownloadListModal rfaContrat={rfaContrat} />
            <ARTimportListModal
              rfaContrat={rfaContrat}
              refreshData={refreshData}
            />
            <ARTclotureModal
              rfaContrat={rfaContrat}
              refreshArticles={refreshArticles}
              toggleNotification={toggleNotification}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ARTactionsList;
