import React from 'react';
import { Outlet } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import Footer from './Footer';
import './style.scss';
import Nav from './Nav';
import LoaderApp from '../../ui/loaders/LoaderApp';
import logoIcon from './logo-referencia.png';

const AppLayout = () => {
  const { isLoading } = useAuth();

  if (isLoading) return <LoaderApp />;
  return (
    <div className="app-layout">
      <div className="app-layout__nav">
        <Nav />
      </div>
      <div className="app-layout__bg">
        <img src={logoIcon} alt="logo inedis" />
      </div>

      <div className="app-layout__body">
        <Outlet />
      </div>

      <div className="app-layout__footer">
        <Footer />
      </div>
    </div>
  );
};

export default AppLayout;
