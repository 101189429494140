import { useState } from 'react';
import { ANIMATION_CLASS } from '../config/constants';

/**
 * Hook pour l'utilisation des drawers
 */
const useDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const time = 1000; // Durée d'animation slide

  // animation du modal
  const [animation, setAnimation] = useState({
    right: ANIMATION_CLASS.SLIDE_IN_RIGHT,
    left: ANIMATION_CLASS.SLIDE_IN_LEFT,
  });

  const openDrawer = () => {
    setAnimation({
      ...animation,
      right: ANIMATION_CLASS.SLIDE_IN_RIGHT,
      left: ANIMATION_CLASS.SLIDE_IN_LEFT,
    });
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setAnimation({
      ...animation,
      right: ANIMATION_CLASS.SLIDE_OUT_RIGHT,
      left: ANIMATION_CLASS.SLIDE_OUT_LEFT,
    });
    new Promise((r) => setTimeout(r, time)).then(() => {
      setIsOpen(false);
    });
  };

  const data = {
    isOpen,
    openDrawer,
    closeDrawer,
    animation,
  };

  return data;
};

export default useDrawer;
