import React from "react";

/**
 * Pagination pour la table
 */
const Pagination = ({ props, pageSizeOptions, footer }) => {
  const {
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = props;

  if (!props) return null;
  return (
    <div className="pagination">
      <div className="pagination__action  ">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>
        <span>
          <strong className="page-indicator">
            page {pageIndex + 1} / {pageOptions.length}
          </strong>
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {pageSizeOptions.map(
            (
              pageSize // remplacer par ma pagination
            ) => (
              <option key={pageSize} value={pageSize}>
                Montrer {pageSize}
              </option>
            )
          )}
        </select>
      </div>
      <div>
        <pre>
          <code>{JSON.stringify(state.filters, null, 2)}</code>
        </pre>
      </div>
      <div className="pagination__info">{footer}</div>
    </div>
  );
};

export default Pagination;
