import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PATH } from '../../../config/constants';
import useAuth from '../../../hooks/useAuth';

/**
 * Guard Administration
 */
const ReferenciaRoute = ({ outlet }) => {
  const location = useLocation();
  const { isAdmin, isReferencia } = useAuth();

  // Retourne la page désirée si l'utilisateur est référencia ou admin
  if (isAdmin() || isReferencia()) {
    return outlet;

    // Retourne la page de non authentification si l'utilisateur n'est pas autoriser à y acceder
  } else {
    return (
      <Navigate
        to={{ pathname: PATH.UNAUTHORIZED }}
        replace
        state={{ from: location }}
      />
    );
  }
};

export default ReferenciaRoute;
