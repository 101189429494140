import React from "react";
import "./style.scss";
import Table from "../../../Table";

const ARTmagasinTable = ({ article }) => {
  const columns = React.useMemo(() => [
    {
      Header: "Référence",
      accessor: "Reference",
      width: 70,
    },
    {
      Header: "Gencod",
      accessor: "Gencod",
      width: 70,
    },
    {
      Header: "Désignation",
      accessor: "Designation",
      width: 250,
    },
    {
      Header: "PA Brut",
      accessor: "Brut",
      width: 100,
      Cell: ({ value }) => `${parseFloat(value).toFixed(2)} €`,
    },
    {
      Header: "Remise",
      accessor: "Remise",
      width: 100,
      Cell: ({ value }) => `${parseFloat(value).toFixed(2)} %`,
    },
    {
      Header: "Début Validité",
      accessor: "Valid_Debut",
      width: 100,
      Cell: ({ value }) => {
        const dateParts = value.split("-");
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      },
    },
    {
      Header: "Fin Validité",
      accessor: "Valid_Fin",
      width: 100,
      Cell: ({ value }) => {
        const dateParts = value.split("-");
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      },
    },
    {
      Header: "PA Brut",
      accessor: "Att_Brut",
      width: 100,
      Cell: ({ value }) => `${parseFloat(value).toFixed(2)} €`,
      hideable: true,
    },
    {
      Header: "Remise",
      accessor: "Att_Remise",
      width: 100,
      Cell: ({ value }) => `${parseFloat(value).toFixed(2)} %`,
      hideable: true,
    },
    {
      Header: "Début Validité",
      accessor: "Att_Valid_Debut",
      width: 100,
      Cell: ({ value }) => {
        const dateParts = value.split("-");
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      },
      hideable: true,
    },
    {
      Header: "Fin Validité",
      accessor: "Att_Valid_Fin",
      width: 100,
      Cell: ({ value }) => {
        const dateParts = value.split("-");
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      },
      hideable: true,
    },
  ]);

  return (
    <>
      <div className="declaration-table-overlay">
        <div className="declaration-table">
          <Table
            data={article}
            columns={columns}
            filter
            pagination
            defaultPageSize={100}
            pageSizeOptions={[100, 200, 500]}
            search={{
              placeholder: "Ref fournisseur, Gencod, désignation ...",
            }}
            showLegend={true}
            showTotal={true}
          />
        </div>
      </div>
    </>
  );
};

export default ARTmagasinTable;
