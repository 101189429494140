import * as Yup from 'yup';
import {
  ERROR_MESSAGE,
  REGEX,
} from '../../../../../../config/constants';

const fields = [
  'cm_ca_3',
  'cm_ca_4',
  'cm_ca_5',
  'cm_ca_6',
  'cm_ca_7',
  'cm_ca_8',
  'cm_ca_9',
];

// Regles specifiques
const specificFieldRules = {
  cm_ca_remonte: Yup.string()
    .nullable(true)
    .matches(REGEX.CURRENCY, ERROR_MESSAGE.FORMAT)
    .required(ERROR_MESSAGE.REQUIRED),
  cm_ca_reel: Yup.string()
    .nullable(true)
    .transform((value) => (value === '' ? null : value))
    .matches(/^-?\d+(\.\d{1,2})?$/, ERROR_MESSAGE.FORMAT),
};

// Regles currencies
const genericCurrencyFieldRules = {};
fields.forEach((fieldName) => {
  genericCurrencyFieldRules[fieldName] = Yup.string()
    .nullable(true)
    .transform((value) => (value === '' ? null : value))
    .matches(REGEX.CURRENCY, ERROR_MESSAGE.FORMAT)
    .notRequired();
});

const fieldRules = {
  ...specificFieldRules,
  ...genericCurrencyFieldRules,
};
const schema = Yup.object().shape(fieldRules);

// Fonction de validation des données du formulaire
const validation = async (data) => {
  const errorBag = {};
  await schema.validate(data, { abortEarly: false }).catch((err) => {
    err?.inner.forEach((res) => {
      errorBag[res.path] = res.message;
    });
  });
  return errorBag;
};

export default validation;
