import React from 'react';
import './style.scss';
import WarningIcon from '../../../../../../ui/icons/WarningIcon';
import Button from './../../../../../button/Button';

const DECalertCloturedCard = ({ hide, rfaContrat }) => {
  return (
    <>
      <div className="declaration-alert-cloture">
        <WarningIcon height="50" width="50" />
        <h3 className="declaration-alert-cloture__title">
          Déclarations verrouillées
        </h3>
        <p>
          Vos déclarations de l'année&nbsp;
          {rfaContrat.annee}&nbsp;ont déjà été confirmées.
        </p>
        <p>
          Pour toute demande de modification, contactez votre chef
          produits.
        </p>

        <div className="declaration-alert-cloture__action">
          <Button
            libelle="Compris"
            color="color-neutral"
            onClick={hide}
          />
        </div>
      </div>
    </>
  );
};

export default DECalertCloturedCard;
