import React from 'react';
import NoDataIcon from '../../icons/NoDataIcon';
import './style.scss';

const MissingDataCard = ({ children }) => {
  return (
    <div className="missing-data-wrap">
      <div className="missing-data animate__animated animate__fadeIn">
        <h4>{children}</h4>
        <NoDataIcon width={300} height={300} />
      </div>
    </div>
  );
};

export default MissingDataCard;
