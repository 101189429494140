import React from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../../../../../config/constants";
import useModal from "../../../../../../hooks/useModal";
import { clotureDeclarationRfa } from "../../../../../../services/contratsRFA_apis";

import Button from "../../../../button/Button";
import ValidationModal from "../../../ValidationModal";

/**
 * Affiche le modal de cloture des déclarations
 * @param rfaContrat le rfaContrat concerné
 */
const DECclotureModal = ({ rfaContrat }) => {
  const modalState = useModal();
  const navigate = useNavigate();

  /**
   * Fonction de cloture
   */
  const handleCloture = async () => {
    await clotureDeclarationRfa(rfaContrat.con_code, rfaContrat.annee);
    navigate(PATH.HOME);
  };

  return (
    <>
      <Button
        libelle="Clôturer la déclaration"
        onClick={() => modalState.toggleModal()}
        color="primary-btn"
      />
      <ValidationModal
        modalState={modalState}
        onConfirmation={() => handleCloture()}
        title="Clôturer votre déclaration"
      >
        <div className="cloture-declaration-text">
          <span>
            Une fois clôturées, vous ne pourrez plus revenir sur vos
            déclarations.
          </span>{" "}
          <br />
          Vous recevrez une copie de votre déclaration par mail.
        </div>
      </ValidationModal>
    </>
  );
};

export default DECclotureModal;
