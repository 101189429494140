import React from 'react';
import { formatDate } from '../../../../../utils/format';
import './style.scss';

const RFAmagInfoCard = ({ magasin }) => {
  if (!magasin) return null;
  return (
    <div className="info-mag">
      <h4 className="title">Informations magasin</h4>
      <div>
        <p className="info_mag__data">
          <span className="libelle">Code magasin</span>
          &nbsp;{magasin.mag_code}
        </p>
        <p className="info_mag__data">
          <span className="libelle">Enseigne</span>
          &nbsp;{magasin.ens_code}
        </p>
        <p className="info_mag__data">
          <span className="libelle">Ville</span>
          &nbsp;{magasin.adr_ville}
        </p>
        <p className="info_mag__data">
          <span className="libelle">Raison&nbsp;sociale</span>
          &nbsp;{magasin.raisonsociale}
        </p>
        <p className="info_mag__data">
          <span className="libelle">Code postal</span>
          &nbsp;{magasin.codepostal}
        </p>
        {magasin.mag_siret && (
          <p className="info_mag__data">
            <span className="libelle">Siret</span>
            &nbsp;{magasin.mag_siret}
          </p>
        )}
        {magasin.dateentree && (
          <p className="info_mag__data">
            <span className="libelle">Date entrée</span>
            &nbsp;{formatDate(magasin.dateentree)}
          </p>
        )}
        {magasin.datesortie && (
          <p className="info_mag__data">
            <span className="libelle">Date sortie</span>
            &nbsp;{formatDate(magasin.datesortie)}
          </p>
        )}
      </div>
    </div>
  );
};

export default RFAmagInfoCard;
