import React from "react";
import "./style.scss";
import DownloadIcon from "../../../../../icons/DownloadIcon";
import Button from "../../../../../button/Button";
import { DOMAIN } from "../../../../../../../config/env";

/**
 * Téléchargement d'une liste de données vierge
 * @param rfaContrat le rfa contrat concerné
 * @param hide la fonction de fermeture de modal
 */
const DECdownloadListCard = ({ hide, rfaContrat }) => {
  return (
    <>
      <div className="downlist-card">
        <p className="downlist-card__body">
          Une fois remplie, vous pourrez importer vos données à l'aide du bouton
          importer.
        </p>
        <div className="downlist-card__action">
          <a href={`${DOMAIN.URL}article-empty-list/${rfaContrat.con_code}`}>
            <Button
              libelle="Télécharger"
              color="excel-btn"
              icon={<DownloadIcon />}
              onClick={hide}
            />
          </a>
          <Button libelle="Annuler" color="color-neutral" onClick={hide} />
        </div>
      </div>
    </>
  );
};

export default DECdownloadListCard;
