import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PATH } from '../../../config/constants';
import useAuth from '../../../hooks/useAuth';

const AuthRoute = ({ outlet }) => {
  const location = useLocation();
  const { authed } = useAuth();

  // Return component if user is auth
  if (authed) {
    return outlet;
  }
  // Return to login page if user is not auth
  else {
    return (
      <Navigate
        to={{ pathname: PATH.LOGIN }}
        replace
        state={{ from: location }}
      />
    );
  }
};

export default AuthRoute;
