import React from 'react';
import './style.scss';

const CLselect = ({
  label,
  onChange,
  children,
  title,
  error = '',
  ...rest
}) => {
  return (
    <div className="rfa-select">
      <label title={title} className="label">
        {label}
      </label>
      <select {...rest} onChange={onChange}>
        {children}
      </select>
      <span className="error">{error ? <>{error}</> : null}</span>
    </div>
  );
};

export default CLselect;
