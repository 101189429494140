import React from 'react';

const MailBgIcon = ({ height = 100, width = 100 }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0"
        stroke="#523015"
        fill="#ebe5e1"
        d="M20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20Z"
        transform="translate(2,2)"
      ></path>
      <path
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0.5"
        stroke="#523015"
        fill="none"
        d="M20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20Z"
      ></path>
      <path
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0"
        stroke="#523015"
        fill="#ebe5e1"
        d="M20 4H4C2.9 4 2 4.9 2 6L12 13L22 6C22 4.9 21.1 4 20 4Z"
        transform="translate(2,2)"
      ></path>
      <path
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0.5"
        stroke="#523015"
        fill="none"
        d="M20 4H4C2.9 4 2 4.9 2 6L12 13L22 6C22 4.9 21.1 4 20 4Z"
      ></path>
    </svg>
  );
};

export default MailBgIcon;
