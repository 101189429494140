import React from 'react';
import './style.scss';
import { useOutletContext } from 'react-router-dom';
import ADMfournisseursTable from './../../../components/ui/tables/_Administration/ADMfournisseursTable';

const DashboardFournisseur = () => {
  const { fournisseurs } = useOutletContext();
  const { refetchFournisseurs } = useOutletContext();
  return (
    <>
      <div className="dashboard-fournisseur-screen">
        <div className="dashboard-fournisseur">
          <div className="dashboard-fournisseur__head">
            <h3 className="title"> Fournisseurs </h3>
          </div>
          <div className="dashboard-fournisseur__table">
            <ADMfournisseursTable
              data={fournisseurs}
              refreshData={refetchFournisseurs}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardFournisseur;
