import React from 'react';

const NoDataIcon = ({ height, width }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0"
        stroke="#523015"
        fill="#ebe5e1"
        d="M20 13C19.17 12.37 18.13 12 17 12C14.24 12 12 14.24 12 17C12 18.13 12.37 19.17 13 20H3C2.45 20 2 19.55 2 19V3C2 2.45 2.45 2 3 2H15.59C15.85 2 16.11 2.11 16.29 2.29L19.71 5.71C19.89 5.89 20 6.15 20 6.41V13Z"
        transform="translate(2,2)"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        stroke="#523015"
        fill="none"
        d="M20 13C19.17 12.37 18.13 12 17 12C14.24 12 12 14.24 12 17C12 18.13 12.37 19.17 13 20H3C2.45 20 2 19.55 2 19V3C2 2.45 2.45 2 3 2H15.59C15.85 2 16.11 2.11 16.29 2.29L19.71 5.71C19.89 5.89 20 6.15 20 6.41V13Z"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        stroke="#523015"
        d="M12.1 16H6"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        stroke="#523015"
        d="M6 11H16"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        stroke="#523015"
        d="M6 6H11"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0"
        stroke="#523015"
        fill="#ebe5e1"
        d="M17 22C19.7614 22 22 19.7614 22 17C22 14.2386 19.7614 12 17 12C14.2386 12 12 14.2386 12 17C12 19.7614 14.2386 22 17 22Z"
        transform="translate(2,2)"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        stroke="#523015"
        fill="none"
        d="M17 22C19.7614 22 22 19.7614 22 17C22 14.2386 19.7614 12 17 12C14.2386 12 12 14.2386 12 17C12 19.7614 14.2386 22 17 22Z"
      ></path>
      <path
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        stroke="#523015"
        d="M19.12 14.88L14.88 19.12"
      ></path>
      <path
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        stroke="#523015"
        d="M19.12 19.12L14.88 14.88"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0"
        stroke="#523015"
        fill="#ebe5e1"
        d="M15 2V7H20V6.41C20 6.15 19.89 5.89 19.71 5.71L16.29 2.29C16.11 2.11 15.85 2 15.59 2H15Z"
        transform="translate(2,2)"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        stroke="#523015"
        fill="none"
        d="M15 2V7H20V6.41C20 6.15 19.89 5.89 19.71 5.71L16.29 2.29C16.11 2.11 15.85 2 15.59 2H15Z"
      ></path>
    </svg>
  );
};

export default NoDataIcon;
