import React from 'react';
import './style.scss';

const DashboardDrawer = ({ drawerStates, children }) => {
  return (
    <div className="drawer-dashboard-overlay ">
      <div
        className={`drawer-dashboard ${drawerStates.animation.left}`}
      >
        <div className="drawer-dashboard-header">
          <h3>
            Tableau de bord
            {/* <span className="drawer-dashboard-close">
              <button
                className="btn"
                onClick={drawerStates.closeDrawer}
              >
                <ArrowSlideToLeft />
              </button>
            </span> */}
          </h3>
        </div>
        <div className="drawer-dashboard-content">{children}</div>
      </div>
    </div>
  );
};

export default DashboardDrawer;
