import { DOMAIN } from '../config/env';
import api from './apiClient';

/**
 *
 * @returns Les bordereaux des litiges en fonction de l'année et du contrat
 */
export async function getBordereaux (con_code) {
  let bordereaux = {};
  await api
    .get(`${DOMAIN.URL_API}litige/bordereau-rfa/${con_code}`)
    .then((res) => {
      bordereaux = res.data;
    });
  return bordereaux;
}

/**
 * Renseigne les informations pour un litige
 */
export async function updateLitige (litige) {
  await api.put(
    DOMAIN.URL_API + 'litFichier/' + litige.lt_numero,
    { ...litige }
  );
}

/**
 * Valide le bordereau et envoie un mail recapitulatif
 */
export async function validateLitige (lev_numero) {
  const data = {
    lev_numero: lev_numero,
  };
  await api.post(
    DOMAIN.URL_API + 'litige/validation-rfa',
    { ...data }
  );
}
