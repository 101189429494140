import React from 'react';
import logo from './logo_referencia.png';
import './style.scss';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer__contact">
          <p>Contactez-nous : +33 2 28 02 29 29</p>
          <div className="logo-wrap">
            <img className="logo" src={logo} alt="logo" />
          </div>
        </div>
        <span className="copyright">
          © Copyright 2022. All Rights Reserved TO.
        </span>
      </footer>
    </>
  );
};

export default Footer;
