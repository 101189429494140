import React, { useState } from "react";
import style from "./style.scss";

import { DataGrid, frFR } from "@mui/x-data-grid";
import CustomPagination from "./Pagination";
import { makeStyles } from "@mui/styles";
import Button from "../../../button/Button";
import useModal from "../../../../../hooks/useModal";
import ADMtoggleClotureModal from "../../../modals/_Administration/ADMtoggleClotureModal";
import ADMinfoDeclarationModal from "./../../../modals/_Administration/ADMinfoDeclarationModal";
import ADMrappelDeclarationModal from "../../../modals/_Administration/ADMrappelDeclarationModal";

const useStyles = makeStyles({
  root: {
    color: style.colorText,
  },
  columnHeaders: {
    backgroundColor: style.colorPrimaryVariant,
    color: style.colorTextVariant,
    borderBottom: `1px solid rgba(${style.colorPrimary}, 0.3)`,
    "&:hover": {
      backgroundColor: `rgba(${style.colorPrimary}, 0.2)`,
    },
  },
  row: {
    border: `1px solid rgba(${style.colorNeutral}, 0.1)`,
    fontSize: "0.8em",
    fontWeight: 400,
  },
  selectedRow: {
    backgroundColor: `rgba(${style.colorPrimaryVariant}, 0.5)`,
  },
  iconButton: {
    color: style.colorPrimary,
    "& .MuiSvgIcon-root": {
      color: style.colorPrimary,
    },
  },
  footerContainer: {
    backgroundColor: `rgba(${style.colorPrimaryVariant}, 0.7)`,
    color: style.colorTextVariant,
  },
  pagination: {
    color: style.colorTextVariant,
  },
});

const ADMcontratsRFATable = ({ data, refreshData }) => {
  const [rows, setRows] = React.useState([]); // Lignes de la table
  const [pageSize] = React.useState(100); // Nombre de lignes affichées par défault
  const modalState = useModal();
  const [declaration, setDeclaration] = useState({}); // Declaration selectionnée
  const classes = useStyles();

  // Colonnes de la table
  const columns = [
    {
      field: "annee",
      headerName: "Année",
    },
    {
      field: "con_code",
      headerName: "Code",
    },
    {
      field: "con_nom",
      headerName: "Fournisseur",
      width: 200,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "che_nom",
      headerName: "Chef produits",
      width: 150,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "lm_libelle",
      headerName: "Liste magasin",
      width: 150,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {params.value?.substring(32)}
          </span>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 300,
      getActions: ({ id }) => {
        const row = rows.filter((row) => {
          const tab = id.split(".");
          const con_code = parseInt(tab[0]);
          const annee = parseInt(tab[1]);

          return (
            parseInt(row.annee) === annee && parseInt(row.con_code) === con_code
          );
        });
        return [
          <>
            <Button
              libelle="Informations"
              color="color-tertiaire"
              onClick={() => {
                setDeclaration(row[0]);
                modalState.toggleModal();
              }}
            />
            {!row[0].statut_declaration && (
              <>
                <ADMrappelDeclarationModal contratRFA={row[0]} />
              </>
            )}
            <ADMtoggleClotureModal
              contratRFA={row[0]}
              refreshData={refreshData}
            />
          </>,
        ];
      },
    },
  ];

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <>
      <ADMinfoDeclarationModal
        modalState={modalState}
        declaration={declaration}
      />
      <div className="contrats-rfa-table">
        <DataGrid
          classes={{
            root: classes.root,
            columnHeaders: classes.columnHeaders,
            cell: classes.cell,
            footerContainer: classes.footerContainer,
            pagination: classes.pagination,
            header: classes.header,
            row: classes.row,
            iconButton: classes.iconButton,
          }}
          rows={rows}
          columns={columns.map((column) => ({
            ...column,
            cellClassName: ({ row }) => {
              return row.statut_declaration ? "declared-row" : "";
            },
          }))}
          getRowId={(row) => row.con_code + "." + row.annee}
          components={{
            Pagination: CustomPagination,
          }}
          experimentalFeatures={{ newEditingApi: true }}
          pageSize={pageSize}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
      </div>
    </>
  );
};

export default ADMcontratsRFATable;
