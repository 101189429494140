import React from "react";
import { Link } from "react-router-dom";
import { PATH } from "../../../config/constants";
import useDrawer from "../../../hooks/useDrawer";
import DashboardDrawer from "../../ui/drawers/DashboardDrawer";
import ListIcon from "../../ui/icons/ListIcon";
import UsersIcon from "../../ui/icons/UsersIcon";
import "./style.scss";

const AdminLayout = ({ children }) => {
  const { isOpen, openDrawer, closeDrawer, animation } = useDrawer();
  const links = [
    {
      label: "Gestion déclarations",
      to: PATH.DASHBOARD_DECLARATIONS,
      icon: <ListIcon />,
    },
    {
      label: "Gestion litiges",
      to: PATH.DASHBOARD_LITIGES,
      icon: <ListIcon />,
    },
    {
      label: "Gestion articles",
      to: PATH.DASHBOARD_ARTICLES,
      icon: <ListIcon />,
    },
    {
      label: "Gestion fournisseurs",
      to: PATH.DASHBOARD_USERS,
      icon: <UsersIcon />,
    },
  ];

  React.useEffect(() => openDrawer(), []);

  return (
    <div className="admin-layout">
      <div className="admin-layout__dashboard">
        <DashboardDrawer
          drawerStates={{
            isOpen,
            closeDrawer,
            animation,
          }}
          title={"Dashboard"}
        >
          <div className="nav-dashboard-overlay">
            <div className="nav-dashboard">
              {links.map((link, i) => (
                <Link
                  className="nav-dashboard-link"
                  to={link.to}
                  key={i + "_dashboard-link"}
                >
                  {link.icon}
                  <span>{link.label}</span>
                </Link>
              ))}
            </div>
          </div>
        </DashboardDrawer>
        ;
      </div>
      <div className="admin-layout__body">{children}</div>
    </div>
  );
};

export default AdminLayout;
