import React, { useState } from 'react';
import './style.scss';
import { toggleSelectDeclarationMagasinRfa } from '../../../../../../../services/apis';
import BasicCheckbox from '../../../../../checkbox/BasicCheckbox';

// Checkbox for select mag
const SelectedMagCell = ({ magasin }) => {
  const [isCheck, setIsCheck] = useState(false);

  const handleCheck = () => {
    const fetchData = async () => {
      const isSelected = await toggleSelectDeclarationMagasinRfa(
        magasin.con_code,
        magasin.mag_code,
        magasin.annee
      );
      setIsCheck(isSelected);
      magasin.is_selected = isSelected;
    };
    fetchData();
  };

  React.useEffect(() => {
    if (magasin.is_selected) {
      setIsCheck(true);
    }
  }, [magasin]);

  return (
    <>
      <div className="select-mag-cell">
        <BasicCheckbox
          title="Afficher uniquement les magasins sélectionnés"
          checked={isCheck}
          onChange={(event) => handleCheck(event)}
        />
      </div>
    </>
  );
};

export default SelectedMagCell;
