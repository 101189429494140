import { useState } from 'react';

/**
 * Hook pour l'utilisation des modals
 */
const useModal = () => {
  const [isShowingMod, setIsShowingMod] = useState(false);
  const time = 500; // Durée d'animation fadeOut
  const animationFadeOut = 'animate__fadeOut';
  const animationFadeIn = 'animate__fadeIn';
  const [animation, setAnimation] = useState(animationFadeIn); // animation du modal

  const toggleModal = () => {
    if (isShowingMod) {
      setAnimation(animationFadeOut);
      new Promise((r) => setTimeout(r, time)).then(() => {
        setIsShowingMod(false);
      });
    } else {
      setAnimation(animationFadeIn);
      setIsShowingMod(true);
    }
  };

  const data = {
    isShowingMod,
    toggleModal,
    animation,
  };

  return data;
};

export default useModal;
