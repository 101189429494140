import * as Yup from 'yup';
import {
  ERROR_MESSAGE,
  REGEX,
} from '../../../../../../config/constants';

const fields = [
  'lt_col3',
  'lt_col4',
  'lt_col5',
  'lt_col6',
  'lt_col7',
  'lt_col8',
  'lt_col9',
];

// Regles specifiques
const specificFieldRules = {
  lt_ar_fourn: Yup.string()
    .nullable(true)
    .matches(REGEX.CURRENCY, ERROR_MESSAGE.FORMAT)
    .required(ERROR_MESSAGE.REQUIRED),
  lt_fourn_comm: Yup.string()
    .max(255, ERROR_MESSAGE.LONG_STRING)
    .required(ERROR_MESSAGE.REQUIRED),
};

// Regles currencies
const genericCurrencyFieldRules = {};
fields.forEach((fieldName) => {
  genericCurrencyFieldRules[fieldName] = Yup.string()
    .nullable(true)
    .transform((value) => (value === '' ? null : value))
    .matches(REGEX.CURRENCY, ERROR_MESSAGE.FORMAT)
    .notRequired();
});

const fieldRules = {
  ...specificFieldRules,
  ...genericCurrencyFieldRules,
};
const schema = Yup.object().shape(fieldRules);

// Fonction de validation des données du formulaire
const validation = async (data) => {
  const errorBag = {};
  await schema.validate(data, { abortEarly: false }).catch((err) => {
    err?.inner.forEach((res) => {
      errorBag[res.path] = res.message;
    });
  });
  return errorBag;
};

export default validation;
