import React from "react";
import style from "./style.scss";
import { DataGrid, frFR } from "@mui/x-data-grid";
import CustomPagination from "./Pagination";
import { makeStyles } from "@mui/styles";
//import Button from "../../../../button/Button";
import ADMclotureArticleModal from "../../../../modals/_Administration/ADMclotureArticleModal";
import ADMrappelArticleModal from "../../../../modals/_Administration/ADMrappelArticleModal";

const useStyles = makeStyles({
  root: {
    color: style.colorText,
  },
  columnHeaders: {
    backgroundColor: style.colorPrimaryVariant,
    color: style.colorTextVariant,
    borderBottom: `1px solid rgba(${style.colorPrimary}, 0.3)`,
    "&:hover": {
      backgroundColor: `rgba(${style.colorPrimary}, 0.2)`,
    },
  },
  row: {
    border: `1px solid rgba(${style.colorNeutral}, 0.1)`,
    fontSize: "0.8em",
    fontWeight: 400,
  },
  selectedRow: {
    backgroundColor: `rgba(${style.colorPrimaryVariant}, 0.5)`,
  },
  iconButton: {
    color: style.colorPrimary,
    "& .MuiSvgIcon-root": {
      color: style.colorPrimary,
    },
  },
  footerContainer: {
    backgroundColor: `rgba(${style.colorPrimaryVariant}, 0.7)`,
    color: style.colorTextVariant,
  },
  pagination: {
    color: style.colorTextVariant,
  },
});

const ADMcontratsRFATable = ({ data, refreshData }) => {
  const [rows, setRows] = React.useState([]); // Lignes de la table
  const [pageSize] = React.useState(100); // Nombre de lignes affichées par défault
  const classes = useStyles();

  // Colonnes de la table
  const columns = [
    {
      field: "code",
      headerName: "Code",
    },
    {
      field: "nom_fournisseur",
      headerName: "Fournisseur",
      width: 200,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "chef_produit",
      headerName: "Chef produits",
      width: 150,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "date_depose",
      headerName: "Date d'ouverture",
      width: 150,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "date_cloture",
      headerName: "Date de cloture",
      width: 150,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 255,
      getActions: ({ id }) => {
        const row = rows.find((row) => parseInt(row.code) === parseInt(id));
        return [
          <>
            {/* <Button
              libelle="Informations"
              color="color-tertiaire"
              onClick={() => {
                setDeclaration(row);
                modalState.toggleModal();
              }}
            /> */}
            {row.statut_cloture === null ? (
              <span>Pas de dossier</span>
            ) : (
              <>
                {!row.statut_cloture && <ADMrappelArticleModal data={row} />}
                <ADMclotureArticleModal data={row} refreshData={refreshData} />
              </>
            )}
          </>,
        ];
      },
    },
  ];

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <>
      <div className="contrats-rfa-table">
        <DataGrid
          classes={{
            root: classes.root,
            columnHeaders: classes.columnHeaders,
            cell: classes.cell,
            footerContainer: classes.footerContainer,
            pagination: classes.pagination,
            header: classes.header,
            row: classes.row,
            iconButton: classes.iconButton,
          }}
          rows={rows}
          columns={columns.map((column) => ({
            ...column,
            cellClassName: ({ row }) => {
              return row.statut_cloture ? "declared-row" : "";
            },
          }))}
          getRowId={(row) => row.code}
          components={{
            Pagination: CustomPagination,
          }}
          experimentalFeatures={{ newEditingApi: true }}
          pageSize={pageSize}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
      </div>
    </>
  );
};

export default ADMcontratsRFATable;
