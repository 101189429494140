import React from 'react';
import useModal from '../../../../../../hooks/useModal';
import Button from '../../../../button/Button';
import DownloadIcon from '../../../../icons/DownloadIcon';
import Modal from '../../../Modal';
import DECdownloadListCard from './DECdownloadListCard';

const DECdownloadListModal = ({ rfaContrat }) => {
  const modalState = useModal();

  return (
    <>
      <Button
        libelle="Liste vierge EXCEL"
        onClick={() => modalState.toggleModal()}
        color="excel-variant-btn"
        icon={<DownloadIcon />}
      />
      <Modal
        modalState={modalState}
        title="Télécharger une liste de données vierge"
        color="excel-modal"
      >
        <DECdownloadListCard
          hide={modalState.toggleModal}
          rfaContrat={rfaContrat}
        />
      </Modal>
    </>
  );
};

export default DECdownloadListModal;
