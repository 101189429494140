import React, { useCallback } from "react";
import "./style.scss";
import { deleteDeclarationMagasinNfRfa } from "../../../../../../services/apis";
import DeleteIcon from "../../../../icons/DeleteIcon";
import Table from "../../../Table";

const DECmagasinManquantTable = ({ data, refreshData, rfaContrat }) => {
  const handleDelete = useCallback(
    (event, magasin) => {
      deleteDeclarationMagasinNfRfa(magasin.id).then(() => {
        refreshData();
      });
    },
    [refreshData]
  );

  // Gere l'affichage des colonnes en fonction du rfa contrat
  const hiddenColumns = React.useMemo(
    () => [
      !rfaContrat.regles.rce_libelle_3 && "cm_ca_3",
      !rfaContrat.regles.rce_libelle_4 && "cm_ca_4",
      !rfaContrat.regles.rce_libelle_5 && "cm_ca_5",
      !rfaContrat.regles.rce_libelle_6 && "cm_ca_6",
      !rfaContrat.regles.rce_libelle_7 && "cm_ca_7",
      !rfaContrat.regles.rce_libelle_8 && "cm_ca_8",
      !rfaContrat.regles.rce_libelle_9 && "cm_ca_9",
    ],
    [rfaContrat]
  );

  // Defini les en-têtes et cellules des colonnes de la table
  const columns = React.useMemo(
    () => [
      {

        Header: "Nom",
        accessor: "mag_nom",
        width: 160,
      },
      {
        Header: "Ville",
        accessor: "adr_ville",
        filter: "includes",
        width: 160,
      },
      {
        Header: "Raison",
        accessor: "raisonsociale",
        filter: "includes",
        width: 180,

        hideable: true,
      },
      {
        Header: "Code postal",
        accessor: "codepostal",
        filter: "includes",
        hideable: true,
      },
      {
        Header: `${rfaContrat.regles.rce_libelle_2}`,
        accessor: "cm_ca_remonte",
        currency: true,
        textAlign: "right",
        width: 150,
      },
      {
        Header: `${rfaContrat.regles.rce_libelle_1}`,
        accessor: "cm_ca_reel",
        currency: true,
        textAlign: "right",
        width: 150,
      },
      {
        Header: `${rfaContrat.regles.rce_libelle_3}`,
        accessor: "cm_ca_3",
        currency: true,
        textAlign: "right",
        width: 160,
      },
      {
        Header: `${rfaContrat.regles.rce_libelle_4}`,
        accessor: "cm_ca_4",
        currency: true,
        textAlign: "right",
        width: 160,
      },
      {
        Header: `${rfaContrat.regles.rce_libelle_5}`,
        accessor: "cm_ca_5",
        currency: true,
        textAlign: "right",
        width: 160,
      },
      {
        Header: `${rfaContrat.regles.rce_libelle_6}`,
        accessor: "cm_ca_6",
        currency: true,
        textAlign: "right",
        width: 100,
      },
      {
        Header: `${rfaContrat.regles.rce_libelle_7}`,
        accessor: "cm_ca_7",
        currency: true,
        textAlign: "right",
        width: 160,
      },
      {
        Header: `${rfaContrat.regles.rce_libelle_8}`,
        accessor: "cm_ca_8",
        currency: true,
        textAlign: "right",
        width: 160,
      },
      {
        Header: `${rfaContrat.regles.rce_libell_9}`,
        accessor: "cm_ca_9",
        currency: true,
        textAlign: "right",
        width: 160,
      },
      {
        Header: "",
        sticky: "right",
        accessor: "id",
        width: 10,
        filter: "equals",
        Cell: (props) => (
          <div className="center-cell">
            {!rfaContrat.statut_declaration && (
              <button
                type="button"
                onClick={(event) => handleDelete(event, props.row.original)}
                disabled={rfaContrat.statut_declaration}
              >
                <DeleteIcon height={15} width={15} />
              </button>
            )}
          </div>
        ),
      },
    ],
    [handleDelete, rfaContrat]
  );

  if (!data) return null;
  return (
    <>
      <div className="declaration-table-nf-overlay">
        <h3 className="table-title"> Magasins ajoutés</h3>
        <div className="declaration-table-nf">
          <Table data={data} columns={columns} hiddenColumns={hiddenColumns} />
        </div>
      </div>
    </>
  );
};

export default DECmagasinManquantTable;
