import React from "react";
import useModal from "../../../../../../hooks/useModal";
import Button from "../../../../button/Button";
import DownloadIcon from "../../../../icons/DownloadIcon";
import Modal from "../../../Modal";
import ARTdownloadListCard from "./ARTdownloadListCard";

const ARTdownloadListModal = ({ rfaContrat }) => {
  const modalState = useModal();

  return (
    <>
      <Button
        libelle="Télécharger Excel"
        onClick={() => modalState.toggleModal()}
        color="excel-variant-btn"
        icon={<DownloadIcon />}
      />
      <Modal
        modalState={modalState}
        title="Télécharger une liste de données vierge"
        color="excel-modal"
      >
        <ARTdownloadListCard
          hide={modalState.toggleModal}
          rfaContrat={rfaContrat}
        />
      </Modal>
    </>
  );
};

export default ARTdownloadListModal;
