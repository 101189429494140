import React from 'react';
import { formatPrix } from '../../../../../../../utils/format';
import AlertModal from '../../../../AlertModal';
import './style.scss';


const AlertImportModal = ({
  errors,
  total,
  rfaContrat,
  ...props
}) => {
  return (
    <>
      <AlertModal {...props}>
        <div className="alert-import-modal">
          <h3 className="title">
            {rfaContrat.regles.rce_libelle_2} importé :{' '}
            {formatPrix(total)}
          </h3>
          {!!errors.nb_lignes && (
            <>
              <span className="forward">
                Toutes les lignes n'ont pas été importées.
                <br />
                Vérifier la ou les lignes suivantes&nbsp;:&nbsp;
              </span>
              {errors.lignes.map((l) => l).join(', ')}
            </>
          )}
        </div>
      </AlertModal>
    </>
  );
};

export default AlertImportModal;
