import React from "react";
import ADMnotifyLitigeModal from "../../../modals/_Administration/ADMnotifyLitigeModal";
import ADMvalidationLitigeModal from "../../../modals/_Administration/ADMvalidationLitigeModal";
import Table from "../../Table";

const ADMbordereauxTable = ({ bordereaux, refetchData }) => {
  // Colonnes de la table
  const hiddenColumns = React.useMemo(() => ["lev_position"], []);
  const columns = [
    {
      Header: "Annee",
      accessor: "lev_annee",
      width: 0,
      textAlign: "right",
    },
    {
      Header: "Con_code",
      accessor: "con_code",
      filter: "includes",
      width: 0,
      textAlign: "right",
    },

    {
      Header: "Fournisseur",
      accessor: "contrat.con_nom",
      width: 160,
    },
    {
      Header: "Chef produit",
      accessor: "contrat.chef_produit.che_nom",
      filter: "includes",
      width: 100,
    },
    {
      Header: "Généré le",
      accessor: "lev_creat_date",
      width: 100,
      textAlign: "right",
    },
    {
      Header: "Position",
      accessor: "lev_position",
    },
    {
      Header: "Actions",
      sticky: "right",
      //   accessor: 'mag_code',
      width: 100,
      Cell: (props) => (
        // <div className="center-cell" title="">
        //   <LITlitigeDrawer
        //     litige={props.row.original}
        //     regles={regles}
        //     refetchData={refetchData}
        //     bordereau={bordereau}
        //   />
        //   {/* <LITlitigeModal
        //       litige={props.row.original}
        //       regles={regles}
        //       refetchData={refetchData}
        //       bordereau={bordereau}
        //     /> */}
        // </div>
        <div className="center-cell">
          <ADMvalidationLitigeModal
            bordereau={props.row.original}
            refetchData={refetchData}
          />
          <ADMnotifyLitigeModal bordereau={props.row.original} />
        </div>
      ),
    },
  ];

  /**
   * Modifye la couleur de la ligne si celle ci est validée
   */
  const getRowProps = (row) => ({
    className: row.values.lev_position > 1 ? "positive-row" : "",
  });

  if (!bordereaux.length) return null;
  return (
    <Table
      data={bordereaux}
      columns={columns}
      search={{
        placeholder: "Rechercher",
      }}
      //   filter
      hiddenColumns={hiddenColumns}
      getRowProps={getRowProps}
      pagination
    />
  );
};

export default ADMbordereauxTable;
