import React from 'react';
import useModal from '../../../../../../hooks/useModal';
import Button from '../../../../button/Button';
import ValidationModal from '../../../ValidationModal';
import useNotification from '../../../../../../hooks/useNotification';
import BasicNotification from '../../../../notifications/BasicNotification';
import { resetDeclarationRfa } from '../../../../../../services/apis';

/**
 * Affiche le modal pour réinitialiser la saisie
 * @param rfaContrat le rfaContrat concerné
 * @param refreshData la fonction pour refresh les données du tableau
 */
const DECresetMagsModal = ({ refreshData, rfaContrat }) => {
  const modalState = useModal();
  const { isShowingNot, toggleNotification } = useNotification();

  const handleReset = async () => {
    try {
      await resetDeclarationRfa(
        rfaContrat.con_code,
        rfaContrat.annee
      );
      refreshData();
      toggleNotification();
      modalState.toggleModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <BasicNotification
        isShowing={isShowingNot}
        hide={toggleNotification}
        text="Données réinitialisées"
      />
      <Button
        libelle="Annuler la saisie"
        onClick={() => modalState.toggleModal()}
        color="color-danger"
      />
      <ValidationModal
        modalState={modalState}
        title="Réinitialiser la saisie"
        color="danger-modal"
        icon="trash"
        onConfirmation={() => handleReset()}
      >
        <>Toutes les données enregistrées disparaitrons.</>
      </ValidationModal>
    </>
  );
};

export default DECresetMagsModal;
