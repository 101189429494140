import React from "react";
import useModal from "../../../../../../hooks/useModal";
import useNotification from "../../../../../../hooks/useNotification";
import { updateEmail } from "../../../../../../services/user_apis";
import InputModal from "../../../../modals/InputModal";
import EditIcon from "../../../../icons/EditIcon";
import Button from "../../../../button/Button";
import BasicNotification from "../../../../notifications/BasicNotification";

const EditEmailModal = ({ email, id, refreshData }) => {
  const modalState = useModal();
  const { isShowingNot, toggleNotification } = useNotification();

  const handleUpdateEmail = async (newEmail) => {
    console.log(newEmail);
    try {
      await updateEmail(id, newEmail);
      toggleNotification();
      modalState.toggleModal();
      refreshData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button
        libelle=" "
        onClick={() => modalState.toggleModal()}
        color="color-neutral"
        icon={<EditIcon with={18} height={18} />}
      />
      <InputModal
        modalState={modalState}
        title="Modifier l'email du fournisseur"
        color="primary-modal-bis"
        icon="mail"
        onConfirmation={handleUpdateEmail}
      >
        <>Voici l'email actuel : {email}</>
      </InputModal>
      <BasicNotification
        isShowing={isShowingNot}
        hide={toggleNotification}
        text="Le mail a bien été modifié"
      />
    </>
  );
};

export default EditEmailModal;
