import { DOMAIN } from '../config/env';
import api from './apiClient';

// Retourne le rfa con le plus récent associé au con_code passé en parametre
export async function getMostRecentRfaCon (con_code) {
  let rfacon = null;
  const data = {
    con_code: con_code,
  };
  await api
    .post(
      DOMAIN.URL_API + 'most-recent-rfa',
      { ...data },
    )
    .then(async (res) => {
      rfacon = await res.data;
    });
  return rfacon;
}
/**
 * Cloture de la déclaration par l'utilisateur
 */
export async function clotureDeclarationRfa (con_code, annee) {
  await api.get(
    DOMAIN.URL_API +
    'cloture-declaration-rfa/' +
    con_code +
    '/' +
    annee,
  );
}

/**
 * Toggle sur l'état de cloture d'une déclaration
 */
export async function toggleClotureDeclarationRFA (con_code, annee) {
  const data = {
    con_code: con_code,
    annee: annee,
  };

  await api.put(
    DOMAIN.URL_API + 'toggle-declaration',
    { ...data },
  );
}
/**
 * Envoie un mail de rappel pour la cloture des declarations
 */
export async function RappelDeclarationRFA (con_code, annee) {
  const data = {
    con_code: con_code,
    annee: annee,
  };
  await api.post(
    DOMAIN.URL_API + 'rappel-declaration-rfa',
    { ...data },
  );
}

/**
 * Envoie un mail de rappel pour la cloture des declarations a tous les fournisseurs
 * n'ayant pas encore clôturé
 */
export async function RappelDeclarationRFAAll () {
  await api.get(DOMAIN.URL_API + 'rappel-declaration-rfa-all');
}

/**
 * Récupères tous les contrats rfa
 */
export async function getContratsRFA () {
  let contratsRFA = {};
  await api
    .get(`${DOMAIN.URL_API}rfaContrat`)
    .then(async (res) => {
      contratsRFA = await res.data;
    });
  return contratsRFA;
}
