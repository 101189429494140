import React, { useState } from "react";
import "./style.scss";
import { saveDeclarationMagasinNfRfa } from "../../../../../../services/apis";
import RFAinput from "../../../../inputs/_RFA/RFAinput";
import Button from "../../../../button/Button";
import validation from "./validation";

/**
 * Formulaire pour la saisie de données de déclaration d'un magasin qui n'est pas dans la liste.
 * @param refreshData la fonction pour recharger les données
 * @param hide pour fermer le modal
 * @param notify pour afficher la notification
 */
const DECmagasinManquantForm = ({ refreshData, hide, rfaContrat }) => {
  const [errors, setErrors] = useState({}); // Erreurs pour le formulaire
  const [loading, setLoading] = useState(false); // Indicateur de chargement lors d'un submit

  // Données du formulaire
  const [data, setData] = useState({
    con_code: rfaContrat.con_code,
    annee: rfaContrat.annee,
    mag_nom: "",
    adr_ville: "",
    raisonsociale: "",
    codepostal: "",
    cm_ca_remonte: "",
    cm_ca_reel: "",
    cm_ca_3: "",
    cm_ca_4: "",
    cm_ca_5: "",
    cm_ca_6: "",
    cm_ca_7: "",
    cm_ca_8: "",
    cm_ca_9: "",
  });
  const nbColSuppMax = 7; // Nombre de colonnes supplémentaires pouvant être ajoutées

  // Modification des valeurs des champs
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

  // Action sur submition du formulaire
  const handleSubmit = (event) => {
    const validate = async () => {
      const errorBag = await validation(data);
      setErrors(errorBag);
      const hasErrors = Object.keys(errorBag).length;
      if (hasErrors) {
        throw new Error("Champs invalides");
      }
    };
    try {
      event.preventDefault();
      validate().then(() => {
        // Enregistrement des données pour le magasin manquant
        const doSaveMagasinManquant = async (data) => {
          await saveDeclarationMagasinNfRfa(data);
          refreshData();
          hide();
        };
        setLoading(true);
        doSaveMagasinManquant(data).then(() => setLoading(false));
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="mag-manquant-form">
        <form className="form" onSubmit={(event) => handleSubmit(event)}>
          <h4 className="title"> Informations magasin</h4>
          <div className="form__input-list">
            <RFAinput
              type="text"
              label="Nom"
              name="mag_nom"
              value={data.mag_nom}
              placeholder="Saisir le nom"
              disabled={rfaContrat.isDeclared}
              onChange={handleChange}
              error={errors.mag_nom}
            />
            <RFAinput
              type="text"
              label="Ville "
              name="adr_ville"
              value={data.adr_ville}
              placeholder="Saisir la ville"
              disabled={rfaContrat.isDeclared}
              onChange={handleChange}
              error={errors.adr_ville}
            />
            <RFAinput
              type="text"
              label="Raison sociale"
              name="raisonsociale"
              value={data.raisonsociale}
              placeholder="Saisir la raison sociale"
              disabled={rfaContrat.isDeclared}
              onChange={handleChange}
              error={errors.raisonsociale}
            />
            <RFAinput
              label="Code postal"
              name="codepostal"
              value={data.codepostal}
              placeholder="Saisir le code postal"
              disabled={rfaContrat.isDeclared}
              onChange={handleChange}
              error={errors.codepostal}
            />
          </div>
          <h4 className="title"> Saisie de la déclaration </h4>
          <div className="form__input-list">
            <RFAinput
              label={rfaContrat.regles.rce_libelle_2}
              name="cm_ca_remonte"
              title={rfaContrat.regles.rce_libelle_2}
              value={data.cm_ca_remonte}
              placeholder="Saisir un montant"
              disabled={rfaContrat.isDeclared}
              onChange={handleChange}
              error={errors.cm_ca_remonte}
              currency
            />
            <RFAinput
              label={rfaContrat.regles.rce_libelle_1}
              name="cm_ca_reel"
              title={rfaContrat.regles.rce_libelle_1}
              value={data.cm_ca_reel}
              placeholder="Saisir un montant"
              disabled={rfaContrat.isDeclared}
              onChange={handleChange}
              error={errors.cm_ca_reel}
              currency
            />
            {Array.from({ length: nbColSuppMax }, (_, i) => i + 3).map(
              (index) => {
                const libelle = rfaContrat.regles[`rce_libelle_${index}`];
                const value = data[`cm_ca_${index}`];
                const name = `cm_ca_${index}`;
                const error = errors[name];
                return (
                  libelle && (
                    <RFAinput
                      key={name}
                      label={libelle}
                      name={name}
                      value={value}
                      placeholder={`${
                        !rfaContrat.statut_declaration
                          ? "Saisir un montant"
                          : ""
                      }`}
                      currency
                      onChange={handleChange}
                      error={error}
                      disabled={rfaContrat.statut_declaration}
                    />
                  )
                );
              }
            )}
          </div>
          <div className="form__action">
            <Button
              type="submit"
              libelle="Enregistrer"
              color="primary-btn"
              loading={loading}
            />
            <Button libelle="Annuler" color="color-neutral" onClick={hide} />
          </div>
        </form>
      </div>
    </>
  );
};

export default DECmagasinManquantForm;
