import React from 'react';
import Modal from '../../../Modal';
import DECimportFileForm from './DECimportFileForm';
import ImportIcon from '../../../../icons/ImportIcon';
import Button from '../../../../button/Button';
import useModal from '../../../../../../hooks/useModal';

const DECimportListModal = ({ refreshData, rfaContrat }) => {
  const modalState = useModal();

  return (
    <>
      <Button
        libelle="Importer un EXCEL"
        onClick={() => modalState.toggleModal()}
        color="color-tertiaire-variant"
        icon={<ImportIcon />}
      />
      <Modal
        modalState={modalState}
        title="Importer une liste de données EXCEL"
        color="excel-modal"
      >
        <DECimportFileForm
          refreshData={refreshData}
          hide={modalState.toggleModal}
          rfaContrat={rfaContrat}
        />
      </Modal>
    </>
  );
};

export default DECimportListModal;
