import * as React from "react";
import "./style.scss";
import { GridRowModes } from "@mui/x-data-grid";
import { DataGrid, frFR } from "@mui/x-data-grid";
import CustomPagination from "./Pagination";
import ADMportalLinkModal from "../../../modals/_Administration/ADMportalLinkModal";
import EditEmailModal from "./EditEmailModal";
import UserContrat from "./UserContrat";


const ADMfournisseursTable = ({ data, refreshData }) => {
  const [rows, setRows] = React.useState([]); // Lignes de la table
  const [pageSize] = React.useState(100); // Nombre de lignes affichées par défault
  const [rowModesModel, setRowModesModel] = React.useState({});

  //const [email, setEmail] = React.useState("");
  const [email] = React.useState("");

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // const handleEditClick = (id) => () => {
  //   const row = rows.filter((row) => row.email === id)[0]; // Récupère la ligne
  //   setEmail(row.email);
  //   setRowModesModel({
  //     ...rowModesModel,
  //     [id]: { mode: GridRowModes.Edit },
  //   });
  // };

  // const handleSaveClick = (id) => () => {
  //   setRowModesModel({
  //     ...rowModesModel,
  //     [id]: { mode: GridRowModes.View },
  //   });
  // };

  // const handleCancelClick = (id) => () => {
  //   setRowModesModel({
  //     ...rowModesModel,
  //     [id]: { mode: GridRowModes.View, ignoreModifications: true },
  //   });

  //   const editedRow = rows.find((row) => row.id === id);
  //   if (editedRow.isNew) {
  //     setRows(rows.filter((row) => row.id !== id));
  //   }
  // };

  const processRowUpdate = (newRow) => {
    console.log(newRow);
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.email === newRow.email ? updatedRow : row)));
    // update d'un user + mise à jour
    const doUpdateFournisseur = async (data) => {
      try {
        console.log(data, email);

        // await updateFournisseur(data);
        refreshData();
      } catch (error) {
        console.log(error);
      }
    };
    doUpdateFournisseur(newRow);
    return updatedRow;
  };

  const columns = [
    {
      field: "name",
      headerName: "Nom",
      width: 250,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="email-cell">
            <span className="custom-cell" title={params.value}>
              {params.value}
            </span>
            <EditEmailModal
              email={params.row.email}
              id={params.row.id}
              refreshData={refreshData}
            />
          </div>
        );
      },
    },
    {
      field: "activity",
      headerName: "Activité",
      width: 100,
      renderCell: (params) => {
        return (
          <span className="custom-cell" title={params.value}>
            {!!parseInt(params.value) ? "actif" : "inactif"}
          </span>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Créé le",
      width: 130,
    },
    {
      field: "updated_at",
      headerName: "Modifié le",
      width: 130,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 220,
      cellClassName: "actions",

      getActions: (params) => { // Utilise params pour accéder à la ligne
        const row = rows.find((r) => r.id === params.id); // Trouver la ligne correspondant à l'ID

        return [
          <ADMportalLinkModal email={row?.email} />, // Utiliser row.email
          <UserContrat userId={row?.id} />,          // Utiliser row.id
        ];
      },
    },
  ];

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <>
      <div className="fournisseurs-table">
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          //getRowId={(row) => row.email}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}

          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}

          components={{
            Pagination: CustomPagination,
          }}
          componentsProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          pageSize={pageSize}
          autoPageSize
          pagination
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
      </div>
    </>
  );
};

export default ADMfournisseursTable;
