import React from 'react';
import './style.scss';
import PRFupdatePasswordForm from '../../components/ui/forms/_Profile/PRFupdatePasswordForm';

const Profile = () => {
  return (
    <>
      <div className="profile">
        <PRFupdatePasswordForm />
      </div>
    </>
  );
};

export default Profile;
