import React, { useState } from 'react';
import './style.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/ui/button/Button';
import RFAselect from '../../components/ui/inputs/_RFA/RFAselect';
import { PATH } from '../../config/constants';
import useContrat from '../../hooks/useContrat';

/**
 * Page de selection de contrat à manipuler
 */
const SelectionContrat = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { contratsList, setContrat } = useContrat();
  const [selectedContrat, setSelectedContrat] = useState(
    contratsList[0].con_code
  );

  /**
   * Changement de selection de contrat
   */
  const handleChange = (event) => {
    setSelectedContrat(parseInt(event.target.value));
  };

  /**
   * Validation du contrat selectionné
   */
  const validateContrat = () => {
    const contrat = contratsList.filter(
      (contrat) => parseInt(selectedContrat) === contrat.con_code
    );
    setContrat(contrat[0]);
    navigate(location.state?.from?.pathname);
  };

  React.useEffect(() => {
    if (!location.state?.from?.pathname) {
      navigate(PATH.HOME);
    }
  }, [location, navigate]);

  return (
    <>
      <div className="selection-contrat-wrap">
        <div className="selection-contrat animate__animated animate__fadeIn">
          <h3> Selectionner un contrat </h3>
          <RFAselect
            name="con_code"
            label="Contrat"
            value={selectedContrat}
            onChange={(event) => handleChange(event)}
          >
            {contratsList.length &&
              contratsList.map((contrat) => (
                <option
                  key={contrat.con_code}
                  value={contrat.con_code}
                >
                  {contrat.con_nom}
                </option>
              ))}
          </RFAselect>
          <Button
            libelle="Selectionner"
            color="primary-btn"
            onClick={() => validateContrat()}
          />
        </div>
      </div>
    </>
  );
};

export default SelectionContrat;
