import React from 'react';
import './style.scss';
import Modal from '../Modal';
import Button from '../../button/Button';

const AlertModal = ({ children, onClick, bodyicon, ...props }) => {
  return (
    <Modal {...props}>
      <div className="alert-modal">
        {bodyicon}
        <div className="alert-modal__body">{children}</div>

        <div className="alert-modal__action">
          <Button
            libelle="Compris"
            color={props.color}
            onClick={() => onClick()}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AlertModal;
