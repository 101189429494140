import React, { useCallback, useState } from 'react';
import './style.scss';
import { useOutletContext } from 'react-router-dom';
import {
  declarationMagasinsListRfa,
  declarationMagasinsNfListRfa,
} from '../../../services/apis';
import { getMostRecentRfaCon } from '../../../services/contratsRFA_apis';
import LoaderApp from '../../../components/ui/loaders/LoaderApp';
import DECmagasinManquantTable from '../../../components/ui/tables/_RFA/_Declaration/DECmagasinManquantTable';
import DECmagasinTable from '../../../components/ui/tables/_RFA/_Declaration/DECmagasinTable';
import RFAalertDeclarationCloture from './../../../components/ui/modals/_RFA/_Declaration/DECalertCloturedModal';
import RFADEClistAction from './../../../components/_rfa/_Declaration/DECactionsList';
import useContrat from '../../../hooks/useContrat';
import MissingDataCard from '../../../components/ui/cards/MissingDataCard';

const Declaration = () => {
  const { contrat } = useContrat();
  const [rfaContrat, setRfaContrat] = useState(null);
  const [loadingRFA, setLoadingRFA] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [magasins, setMagasins] = useState({});
  const [magasinsNf, setMagasinsNf] = useState({});
  const [filteredMagasins, setFilteredMagasins] = useState({});

  // Récupere les données des magasins
  const fetchData = useCallback(async () => {
    try {
      // On récupère les magasins pour le rfa contrat concerné
      const mag = await declarationMagasinsListRfa(
        rfaContrat.con_code,
        rfaContrat.annee
      );

      setMagasins(mag);
      setFilteredMagasins(mag);

      // On récupère les magasins manquants
      const magNf = await declarationMagasinsNfListRfa(
        rfaContrat.con_code,
        rfaContrat.annee
      );
      setMagasinsNf(magNf);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingData(false);
    }
  }, [rfaContrat]);

  const fetchContratRFA = useCallback(async () => {
    try {
      const contratRFA = await getMostRecentRfaCon(contrat.con_code);
      if (contratRFA) {
        setRfaContrat(contratRFA);
      } else {
        throw new Error('Pas de contrat RFA');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingRFA(false);
    }
  }, [contrat]);

  const refreshContratRFA = () => {
    const refetch = async () => {
      await fetchContratRFA();
    };
    refetch();
  };

  // Rafraichis les données
  const refreshData = () => {
    const refetch = async () => {
      await fetchData();
      refreshContratRFA();
    };
    refetch();
  };

  React.useEffect(() => {
    if (contrat) {
      fetchContratRFA();
    }
  }, [fetchContratRFA, contrat]);

  React.useEffect(() => {
    if (rfaContrat) {
      fetchData();
    }
  }, [rfaContrat, fetchData]);

  if (!contrat) return null;
  if (loadingData) return <LoaderApp />;
  // if (!rfaContrat.length) {
  //   return (
  //     <MissingDataCard>
  //       Vous n'avez pas de déclaration à traiter
  //     </MissingDataCard>
  //   );
  // }
  return (
    <>
      <div className="declaration animate__animated animate__fadeIn">
        <header className="declaration__head">
          <h3 className="">
            Déclarations {rfaContrat.annee} <br />
            {contrat.con_nom}
          </h3>
        </header>
        {rfaContrat ? (
          <>
            <section className="declaration__body">
              <RFADEClistAction
                refreshData={refreshData}
                refreshContratRFA={refreshContratRFA}
                rfaContrat={rfaContrat}
              />
              {magasins && !!magasins.length ? (
                <section>
                  <DECmagasinTable
                    magasins={magasins}
                    data={filteredMagasins}
                    setData={setFilteredMagasins}
                    refreshData={refreshData}
                    rfaContrat={rfaContrat}
                  />
                </section>
              ) : (
                <>Pas de magasins</>
              )}
              {magasinsNf && !!magasinsNf.length && (
                <section>
                  <DECmagasinManquantTable
                    data={magasinsNf}
                    rfaContrat={rfaContrat}
                    refreshData={refreshData}
                  />
                </section>
              )}
            </section>
            {!!rfaContrat.statut_declaration && (
              <RFAalertDeclarationCloture rfaContrat={rfaContrat} />
            )}
          </>
        ) : (
          <> Rien à déclarer.</>
        )}
      </div>
    </>
  );
};

export default Declaration;
