import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import CancelIcon from '../../icons/CancelIcon';
import './style.scss';

const colors = {
  'color-primary': true,
};

const defaultColor = 'color-primary';

/**
 * Modal basique.
 */
const Modal = ({
  modalState,
  children,
  title,
  color = defaultColor,
  icon,
}) => {
  const stopPropagation = (e) => e.stopPropagation(); // Empeche la propagation du click pour ne pas fermer le modal.
  if (!colors[color]) color = defaultColor; // Verifie que la couleur existe

  if (!modalState.isShowingMod) return null;
  return ReactDOM.createPortal(
    <>
      <div
        className={`modal-overlay animate__animated ${modalState.animation}`}
      >
        <div
          className={`modal-wrapper`}
          onClick={modalState.toggleModal}
        >
          <div
            className={['modal', color].join(' ')}
            onClick={stopPropagation}
          >
            <div className="modal__head">
              <h3 className="modal-title">
                {icon ? icon : null} {title}
              </h3>
              <button
                type="button"
                className="modal-exit"
                onClick={modalState.toggleModal}
              >
                <CancelIcon width={25} height={25} />
              </button>
            </div>
            <div className="modal__body">{children}</div>
          </div>
        </div>
      </div>
    </>,
    document.body
  );
};

export default Modal;
