import React from 'react';
import useModal from '../../../../../hooks/useModal';
import useNotification from '../../../../../hooks/useNotification';
import { mailPortail } from '../../../../../services/user_apis';
import ValidationModal from '../../ValidationModal';
import MailIcon from '../../../icons/MailIcon';
import Button from '../../../button/Button';
import BasicNotification from '../../../notifications/BasicNotification';

const ADMportalLinkModal = ({ email }) => {
  const modalState = useModal();
  const { isShowingNot, toggleNotification } = useNotification();

  /**
   * Envoie un mail contentant le lien du portail.
   */
  const handleSentPortalLink = async () => {
    try {
      mailPortail(email);
      toggleNotification();
      modalState.toggleModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button
        libelle="Lien vers portail"
        onClick={() => modalState.toggleModal()}
        color="primary-btn"
        icon={<MailIcon with={15} height={15} />}
      />
      <ValidationModal
        modalState={modalState}
        title="Mail lien vers portail"
        color="primary-modal-bis"
        icon="mail"
        onConfirmation={() => handleSentPortalLink()}
      >
        <>Envoyer le lien vers le portail pour {email}</>
      </ValidationModal>
      <BasicNotification
        isShowing={isShowingNot}
        hide={toggleNotification}
        text="Mail envoyé"
      />
    </>
  );
};

export default ADMportalLinkModal;
