import React from 'react';
import ModifyIcon from '../../icons/ModifyIcon';
import './style.scss';

const ModifyButton = ({ onClick, type = 'button', ...rest }) => {
  return (
    <button
      type={type}
      className="modify-btn"
      onClick={onClick}
      {...rest}
    >
      <ModifyIcon width={18} height={18} />
    </button>
  );
};

export default ModifyButton;
