import React from 'react';
import { formatPrix } from '../../../../../../utils/format';
import './style.scss';

const DECtotalTable = ({ rfaContrat }) => {
  return (
    <>
      {rfaContrat && (
        <>
          <div className="total-declare-table">
            <table className="table">
              <thead className="table__head">
                <tr>
                  <th className="cell-head" title="CA ristournable">
                    {rfaContrat.regles.rce_libelle_2}
                  </th>
                  <th
                    className="cell-head"
                    title="CA non ristournable"
                  >
                    {rfaContrat.regles.rce_libelle_1}
                  </th>
                  {rfaContrat.regles.rce_libelle_3 && (
                    <th className="cell-head" title="{{ $regle }}">
                      {rfaContrat.regles.rce_libelle_3}
                    </th>
                  )}
                  {rfaContrat.regles.rce_libelle_4 && (
                    <th className="cell-head" title="{{ $regle }}">
                      {rfaContrat.regles.rce_libelle_4}
                    </th>
                  )}
                  {rfaContrat.regles.rce_libelle_5 && (
                    <th className="cell-head" title="{{ $regle }}">
                      {rfaContrat.regles.rce_libelle_5}
                    </th>
                  )}
                  {rfaContrat.regles.rce_libelle_6 && (
                    <th className="cell-head" title="{{ $regle }}">
                      {rfaContrat.regles.rce_libelle_6}
                    </th>
                  )}
                  {rfaContrat.regles.rce_libelle_7 && (
                    <th className="cell-head" title="{{ $regle }}">
                      {rfaContrat.regles.rce_libelle_7}
                    </th>
                  )}
                  {rfaContrat.regles.rce_libelle_8 && (
                    <th className="cell-head" title="{{ $regle }}">
                      {rfaContrat.regles.rce_libelle_8}
                    </th>
                  )}
                  {rfaContrat.regles.rce_libell_9 && (
                    <th className="cell-head" title="{{ $regle }}">
                      {rfaContrat.regles.rce_libell_9}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="table__body">
                <tr>
                  <td className="cell-body">
                    {formatPrix(rfaContrat.ca.cm_ca_remonte)}
                  </td>
                  <td className="cell-body">
                    {formatPrix(rfaContrat.ca.cm_ca_reel)}
                  </td>
                  {rfaContrat.regles.rce_libelle_3 && (
                    <td className="cell-body">
                      {formatPrix(rfaContrat.ca.cm_ca_3)}
                    </td>
                  )}
                  {rfaContrat.regles.rce_libelle_4 && (
                    <td className="cell-body">
                      {formatPrix(rfaContrat.ca.cm_ca_4)}
                    </td>
                  )}
                  {rfaContrat.regles.rce_libelle_5 && (
                    <td className="cell-body">
                      {formatPrix(rfaContrat.ca.cm_ca_5)}
                    </td>
                  )}
                  {rfaContrat.regles.rce_libelle_6 && (
                    <td className="cell-body">
                      {formatPrix(rfaContrat.ca.cm_ca_6)}
                    </td>
                  )}
                  {rfaContrat.regles.rce_libelle_7 && (
                    <td className="cell-body">
                      {formatPrix(rfaContrat.ca.cm_ca_7)}
                    </td>
                  )}
                  {rfaContrat.regles.rce_libelle_8 && (
                    <td className="cell-body">
                      {formatPrix(rfaContrat.ca.cm_ca_8)}
                    </td>
                  )}
                  {rfaContrat.regles.rce_libell_9 && (
                    <td className="cell-body">
                      {formatPrix(rfaContrat.ca.cm_ca_9)}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default DECtotalTable;
