import React from 'react';
import useModal from '../../../../../../hooks/useModal';
import Button from '../../../../button/Button';
import DECmagasinManquantForm from '../../../../forms/_RFA/_Declaration/DECmagasinManquantForm';
import MagIcon from '../../../../icons/MagIcon';
import Modal from '../../../Modal';

const DECmagManquantModal = ({ refreshData, rfaContrat }) => {
  const modalState = useModal();

  return (
    <>
      <Button
        libelle="Magasin manquant ?"
        onClick={() => modalState.toggleModal()}
        color="secondary-btn"
      />
      <Modal
        modalState={modalState}
        title="Ajouter un magasin"
        color="primary-modal"
        icon={<MagIcon />}
      >
        <DECmagasinManquantForm
          refreshData={refreshData}
          rfaContrat={rfaContrat}
          hide={modalState.toggleModal}
        />
      </Modal>
    </>
  );
};

export default DECmagManquantModal;
