import React, { useState } from "react";
import useModal from "../../../../../../../hooks/useModal";
import { importArticle } from "../../../../../../../services/articles_api";
import Button from "../../../../../button/Button";
import ImportIcon from "../../../../../icons/ImportIcon";
import InfoIcon from "../../../../../icons/InfoIcon";
import AlertImportModal from "../AlertImportModal";
import "./style.scss";

/**
 * Formulaire d'importation de données d'un fichier excel
 * @param rfaContrat le rfa contrat concerné
 * @param refreshData la fonction pour recharger les données
 * @param hide pour fermer le modal
 * @param notify pour afficher la notification
 * */
const ARTimportFileForm = ({ refreshData, hide, notify, rfaContrat }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const errorsInit = { lignes: [], nb_lignes: 0 };
  const [errors, setErrors] = useState(errorsInit); // Erreur de l'importation
  const totalInit = 0;
  const [total, setTotal] = useState(totalInit); // Total CA ristournable importé
  const [loading, setLoading] = useState(false); // Chargement de l'importation
  const modalState = useModal();

  // Gestion du l'input pour le fichier
  const handleFileInput = (event) => {
    if (event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  // Déclanchement de validation du formulaire
  const handleSubmit = (event) => {
    event.preventDefault();
    const doImport = async () => {
      try {
        setErrors(errorsInit);
        setTotal(totalInit);
        setLoading(true);
        const data = await importArticle(selectedFile, rfaContrat.con_code);
        setTotal(data["total"]);
        setErrors(data["errors"]);

        modalState.toggleModal();
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
        refreshData();
        notify();
        // hide();
      }
    };
    doImport();
  };

  React.useEffect(() => {
    console.log("total", total);
    console.log("erreurs", errors);
  }, [total, errors]);

  return (
    <>
      <div className="import-list-form">
        <AlertImportModal
          errors={errors}
          total={total}
          rfaContrat={rfaContrat}
          modalState={modalState}
          title="Informations"
          color="primary-modal-bis"
          bodyicon={<InfoIcon height={60} width={60} />}
          onClick={() => {
            modalState.toggleModal();
            hide();
          }}
        />
        <form onSubmit={(event) => handleSubmit(event)} className="form">
          <div className="form__body">
            <h3 className="form-title">Conditions d'importation</h3>

            <div className="conditions">
              <p className="alert-text">
                Les données importées remplaceront{" "}
                <span className="important">toutes</span> les données déjà
                saisies (annule et remplace). <br />
                Afin d'
                <span className="important">assurer </span> l'importation
                complete de votre fichier, il est{" "}
                <span className="important">nécessaire</span> de respecter les
                conditions d'importation.
              </p>
              <p>
                Le <span className="important">nommage</span> de l'en-tête
                (ligne 1) des colonnes contenant le
                <span className="important"> code du magasin </span>, ainsi que
                celles contenant les{" "}
                <span className="important">données saisies</span>
                sont importantes. <br />
                Merci de respecter celles utilisées dans le fichier excel mis à
                votre disposition en téléchargement. <br />
                L'ordre des colonnes n'est pas important.
              </p>
            </div>
          </div>

          <div className="form__action">
            <input
              className="input-file"
              type="file"
              name="importfile"
              onChange={(event) => handleFileInput(event)}
              // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              required
            />
            <div className="actions-btn">
              <Button
                type="submit"
                loading={loading}
                libelle="Importer"
                color="excel-variant-btn"
                icon={<ImportIcon />}
              />
              <Button libelle="Annuler" color="color-neutral" onClick={hide} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ARTimportFileForm;
