import axios from "axios";
import { PATH } from "../config/constants";
import { DOMAIN } from "../config/env";

const api = axios.create();

// Ajoute l'intercepteur
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Case where the CSRF token is no longer valid (401 error)
    if (error.response.status === 401) {
      localStorage.removeItem("token");

      // Get the current URL
      const currentURL = window.location.href;

      // Define the URL to exclude from error handling
      const excludedURL = new RegExp(`${DOMAIN.URL_APP}#/reset-password/\\w+`);
      console.log(excludedURL);
      // Check if the current URL is the excluded URL
      if (!excludedURL.test(currentURL)) {
        // Redirect to the root URL of the app
        if (
          window.location.href !== DOMAIN.URL_APP + "#" + PATH.LOGIN_PAGE &&
          window.location.href !== DOMAIN.URL_APP
        ) {
          window.location.href = DOMAIN.URL_APP;
        }
      }
    }
    return Promise.reject(error);
  }
);
