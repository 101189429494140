import { REGEX } from '../config/constants';

// Format a given number to a price format
export const formatPrix = (string) => {
  // Vérifie si la chaîne est un nombre valide 
  if (isNaN(string) || !REGEX.CURRENCY.test(string)) {
    return string;
  }

  if (string != null) {
    // Convertit la chaîne en nombre et arrondit à 2 décimales
    const number = Number(string).toFixed(2);

    // Sépare les parties entière et décimale du nombre
    const parts = number.split('.');

    // Formate la partie entière en ajoutant des espaces tous les 3 chiffres
    const integerPart = parts[0].replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ' '
    );

    // Concatène la partie entière et la partie décimale avec une virgule
    const formattedNumber = `${integerPart}.${parts[1]}`;

    // Retourne le nombre formaté en ajoutant le symbole €
    return `${formattedNumber} €`;
  } else {
    return '-';
  }
};

// Format a given date to a dd-mm-yyyy date
export const formatDate = (date) => {
  if (Date.parse(date)) {
    return new Date(Date.parse(date)).toLocaleDateString('fr-FR');
  } else {
    return ' - ';
  }
};
