import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DeleteIcon from "../../../../icons/DeleteIcon";
import Divider from "@mui/material/Divider";

// Assuming you have these functions defined elsewhere to call your APIs
import {
  getUserContracts,
  getAllContracts,
  addContractToUser,
  removeContractFromUser,
} from "../../../../../../services/user_apis";

const UserContratModal = ({ userId }) => {
  const [userContracts, setUserContracts] = useState([]);
  const [allContracts, setAllContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState(null);

  useEffect(() => {
    const fetchUserContracts = async () => {
      const contracts = await getUserContracts(userId);
      setUserContracts(contracts);
    };

    const fetchAllContracts = async () => {
      const contracts = await getAllContracts();
      setAllContracts(contracts);
    };

    fetchUserContracts();
    fetchAllContracts();
  }, [userId]);

  const fetchUserContracts = async () => {
    const contracts = await getUserContracts(userId);
    setUserContracts(contracts);
  };
  const handleAddContract = async () => {
    if (selectedContract) {
      await addContractToUser(userId, selectedContract);
      fetchUserContracts();
    }
  };

  const handleRemoveContract = async (contractId) => {
    await removeContractFromUser(userId, contractId);
    fetchUserContracts();
  };

  const columns = [
    { field: "con_code", headerName: "Code", width: 150 },
    { field: "con_nom", headerName: "Nom", width: 275 },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <Button
          onClick={() => handleRemoveContract(params.row.con_code)}
          variant="contained"
          color="error"
        >
          <DeleteIcon color="white" />
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Box style={{ marginBottom: 20, textAlign: "center" }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Gestion des contrats des utilisateurs
        </Typography>
        <Divider style={{ margin: "8px 0" }} />
      </Box>
      <Box display="flex" alignItems="center" gap={2} marginBottom={2}>
        <Autocomplete
          options={allContracts}
          getOptionLabel={(option) => option.con_nom}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ajouter un contrat"
              variant="outlined"
            />
          )}
          onChange={(event, newValue) => {
            setSelectedContract(newValue);
          }}
        />
        <Button onClick={handleAddContract} variant="contained" color="primary">
          Ajouter
        </Button>
      </Box>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={userContracts}
          getRowId={(row) => row.con_code}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
        />
      </div>
    </div>
  );
};

export default UserContratModal;
