import React from "react";
import useModal from "../../../../../hooks/useModal";
import useNotification from "../../../../../hooks/useNotification";
import { toggleClotureArticle } from "../../../../../services/articles_api";
import ValidationModal from "../../ValidationModal";
import BasicNotification from "../../../notifications/BasicNotification";
import Button from "../../../button/Button";

const ADMclotureArticleModal = ({ refreshData, data }) => {
  const modalState = useModal();
  const { isShowingNot, toggleNotification } = useNotification();

  /**
   * Fonction clôturant ou décloturant une déclaration
   * @param data la déclaration concernée
   */
  const handleToggleCloture = async (data) => {
    try {
      await toggleClotureArticle(data.code);
      refreshData();
      toggleNotification();
      modalState.toggleModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button
        libelle={!!data.statut_cloture ? "Réouvrir" : "Clôturer"}
        color={
          !!data.statut_cloture ? "color-tertiaire-variant" : "color-danger"
        }
        onClick={() => modalState.toggleModal()}
      />
      <ValidationModal
        modalState={modalState}
        title={!!data.statut_cloture ? "réouvrir" : "clôturer"}
        color="primary-modal-bis"
        onConfirmation={() => handleToggleCloture(data)}
      >
        <>
          {!!data.statut_cloture ? "réouverture" : "clôture"}
          &nbsp;
          {data.nom_fournisseur}
        </>
      </ValidationModal>
      <BasicNotification
        isShowing={isShowingNot}
        hide={toggleNotification}
        text="Modification"
      />
    </>
  );
};

export default ADMclotureArticleModal;
