import React from "react";
import useModal from "../../../../../../hooks/useModal";
import Button from "../../../../button/Button";
import Modal from "../../../Modal";
import ARTclotureCard from "./ARTclotureCard";

const ARTclotureModal = ({
  rfaContrat,
  refreshArticles,
  toggleNotification,
}) => {
  const modalState = useModal();

  return (
    <>
      <Button
        libelle="Cloturer les articles"
        onClick={() => modalState.toggleModal()}
        color="color-danger"
      />
      <Modal
        modalState={modalState}
        title="Voulez vous cloturer le dossier des articles ? "
        color="excel-modal"
      >
        <ARTclotureCard
          hide={modalState.toggleModal}
          rfaContrat={rfaContrat}
          refreshArticles={refreshArticles}
          toggleNotification={toggleNotification}
        />
      </Modal>
    </>
  );
};

export default ARTclotureModal;
