import React from 'react';
import { Link } from 'react-router-dom';

const NavLink = ({
  link,
  selected,
  onClick,
  visible = true,
  forward,
}) => {
  return (
    visible && (
      <>
        <li className={`dropdown-link ${selected ? 'selected' : ''}`}>
          {link.items ? (
            <button className="dropdown-title" onClick={onClick}>
              <span className="link">{link.label}</span>
            </button>
          ) : (
            <>
              <Link to={link.to} onClick={onClick}>
                <span
                  className={`link ${forward ? 'forward-link' : ''}`}
                >
                  {link.label}
                </span>
              </Link>
            </>
          )}

          {link.items && (
            <ul className={`sub-dropdown ${selected ? 'show' : ''}`}>
              {link.items.map((subLink) => (
                <li key={subLink.label}>
                  <Link
                    to={subLink.to}
                    onClick={subLink.onClick}
                    className="link"
                  >
                    {subLink.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      </>
    )
  );
};

export default NavLink;
