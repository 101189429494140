import React, { useState } from 'react';
import './style.scss';

const BasicCheckbox = ({ onChange, label, checked, ...rest }) => {
  return (
    <>
      <div className="checkbox">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          {...rest}
        />
      </div>
    </>
  );
};

export default BasicCheckbox;
