import React from 'react';
import './style.scss';
import ModifyButton from '../../../../button/ModifyButton';
import LITlitigeForm from '../../../../forms/_RFA/_Litige/LITlitigeForm';
import Drawer from '../../../Drawer';
import useDrawer from '../../../../../../hooks/useDrawer';
import { formatDate } from '../../../../../../utils/format';

const LITlitigeDrawer = ({
  litige,
  regles,
  refetchData,
  bordereau,
}) => {
  const { isOpen, openDrawer, closeDrawer, animation } = useDrawer();
  if (!litige) return null;
  return (
    <div className="drawer-container">
      <ModifyButton onClick={openDrawer} />

      <Drawer
        drawerStates={{
          isOpen,
          closeDrawer,
          animation,
        }}
        title={
          <>
            <span className="store-name">
              {litige.rfa_mag.mag_nom}
              <span className="store-name__city">
                {litige.rfa_mag.adr_ville}&nbsp;-&nbsp;
                {litige.rfa_mag.codepostal}
              </span>
              <span className="store-name__sub-info">
                <p>
                  <span className="libelle">Code magasin</span>
                  &nbsp;:&nbsp;{litige.rfa_mag.mag_code}
                </p>
                <p>
                  <span className="libelle">Enseigne</span>
                  &nbsp;:&nbsp;{litige.rfa_mag.enseigne.ens_nom}
                </p>
                <p>
                  <span className="libelle">Raison&nbsp;sociale</span>
                  &nbsp;:&nbsp;{litige.rfa_mag.raisonsociale}
                </p>
                {litige.rfa_mag.mag_depots && (
                  <p className="forward">
                    <span className="libelle">Dépots</span>
                    &nbsp;:&nbsp;{litige.rfa_mag.mag_depots}
                  </p>
                )}
                {litige.rfa_mag.mag_siret && (
                  <p>
                    <span className="libelle">Siret</span>
                    &nbsp;:&nbsp;{litige.rfa_mag.mag_siret}
                  </p>
                )}
                {litige.rfa_mag.dateentree && (
                  <p>
                    <span className="libelle">Date entrée</span>
                    &nbsp;:&nbsp;
                    {formatDate(litige.rfa_mag.dateentree)}
                  </p>
                )}
                {litige.rfa_mag.datasortie && (
                  <p>
                    <span className="libelle">Date sortie</span>
                    &nbsp;:&nbsp;
                    {formatDate(litige.rfa_mag.datesortie)}
                  </p>
                )}
              </span>
            </span>
          </>
        }
      >
        <div className="litige-drawer-overlay">
          <div className="litige-drawer">
            <LITlitigeForm
              litige={litige}
              regles={regles}
              refetchData={refetchData}
              bordereau={bordereau}
              hide={closeDrawer}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default LITlitigeDrawer;
