import React from 'react';
import useModal from '../../../../../../hooks/useModal';
import Modal from '../../../Modal';
import DECalertCloturedCard from './DECalertCloturedCard';

const DECalertCloturedModal = ({ rfaContrat }) => {
  const modalState = useModal();

  // Ouvre le modal a l'ouverture
  React.useEffect(() => {
    modalState.toggleModal();
  }, []);

  return (
    <>
      <Modal
        modalState={modalState}
        title="Déclaration verrouillée"
        color="primary-modal"
      >
        <DECalertCloturedCard
          hide={modalState.toggleModal}
          rfaContrat={rfaContrat}
        />
      </Modal>
    </>
  );
};

export default DECalertCloturedModal;
