import { DOMAIN } from '../config/env';
import api from './apiClient';

// ---------------------------------------------------------------- RFA ----------------------------------------------------------------

/**
 * Reset de la déclaration du rfa con
 * @param {} con_code
 * @param {*} annee
 */
export async function resetDeclarationRfa (con_code, annee) {
  await api.delete(
    DOMAIN.URL_API +
    'reset-declaration-rfa/' +
    con_code +
    '/' +
    annee,
  );
}

/**
 * Import les déclarations de rfa
 * @param {*} data
 * @param {*} con_code
 * @param {*} annee
 */
export async function importDeclarationRfa (file, con_code, annee) {
  let total = 0; // Total importé
  let errors = { lignes: [], nb_lignes: 0 }; // Erreurs lors de l'importation

  const data = {
    importfile: file,
    con_code: con_code,
    annee: annee,
  };
  await api
    .post(
      DOMAIN.URL_API + 'import-declaration-rfa',
      { ...data },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((res) => {
      total = res.data['sum'];
    })
    .catch((err) => {
      console.log(err);
      const errs = err.response.data['errors'];
      errors = {
        ...errors,
        lignes: errs.lignes,
        nb_lignes: errs.nb_lignes,
      };
      total = err.response.data['sum'];
    });
  return { total: total, errors: errors };
}

/**
 * Retourne la liste des magasins déclaré pour le rfa contrat concerné
 * @param {*} con_code
 * @param {*} annee
 * @returns
 */
export async function declarationMagasinsListRfa (con_code, annee) {
  let magasins = {};

  await api
    .get(
      `${DOMAIN.URL_API}magasins-declaration-rfa/${con_code}/${annee}`,
    )
    .then(async (res) => {
      magasins = await res.data;
    });
  return magasins;
}
/**
 * Retourne la liste des magasins manquants déclaré pour le rfa contrat concerné
 * @param {*} con_code
 * @param {*} annee
 * @returns
 */
export async function declarationMagasinsNfListRfa (con_code, annee) {
  let magasins = {};

  await api
    .get(
      `${DOMAIN.URL_API}magasins-nf-declaration-rfa/${con_code}/${annee}`,
    )
    .then(async (res) => {
      magasins = await res.data;
    });
  return magasins;
}

// Unselecte toute la selection d'une liste de magasin
export async function unSelectallDeclarationMagasinRfa (
  con_code,
  annee
) {
  let isAllUnSelected = null;
  // const data = {
  //   con_code: con_code,
  //   annee: annee,
  // };
  await api
    .get(
      DOMAIN.URL_API +
      'magasin-unselect-all-declaration-rfa/' +
      parseInt(con_code) +
      '/' +
      parseInt(annee),
    )
    .then(async (res) => {
      isAllUnSelected = await res.data;
    });

  return isAllUnSelected;
}

// Toggle la selection d'un magasin dans la liste
export async function toggleSelectDeclarationMagasinRfa (
  con_code,
  mag_code,
  annee
) {
  let isSelected = null;
  const data = {
    con_code: con_code,
    annee: annee,
    mag_code: mag_code,
  };
  await api
    .post(
      DOMAIN.URL_API + 'magasin-select-declaration-rfa',
      { ...data },
    )
    .then(async (res) => {
      isSelected = await res.data;
    });

  return isSelected;
}

/**
 * Sauvegarde des données de déclaration pour un magasin
 * @param {*} con_code
 * @param {*} annee
 * @param {*} mag_code
 * @param {*} cm
 */
export async function saveDeclarationMagasinRfa (
  con_code,
  annee,
  mag_code,
  cm
) {
  const data = {
    con_code: con_code,
    annee: annee,
    mag_code: mag_code,
    cm: cm,
  };
  await api.post(
    DOMAIN.URL_API + 'magasin-save-declaration-rfa',
    { ...data },
  );
}

/**
 * Sauvegarde des données de déclaration pour un magasin non trouvé dans la liste
 * @param {*} con_code
 * @param {*} annee
 * @param {*} mag_code
 * @param {*} cm
 */
export async function saveDeclarationMagasinNfRfa (data) {
  await api.post(
    DOMAIN.URL_API + 'magasin-nf-save-declaration-rfa',
    { ...data },
  );
}

/**
 * Suppression des données de déclaration pour un magasin
 * @param {*} con_code
 * @param {*} annee
 * @param {*} mag_code
 */
export async function deleteDeclarationMagasinRfa (
  con_code,
  annee,
  mag_code
) {
  await api.delete(
    `${DOMAIN.URL_API}magasin-delete-declaration-rfa/${con_code}/${mag_code}/${annee}`
  );
}

/**
 * Suppression des données de déclaration pour un magasin
 * @param {*} con_code
 * @param {*} annee
 * @param {*} mag_code
 */
export async function deleteDeclarationMagasinNfRfa (id) {
  await api.delete(
    `${DOMAIN.URL_API}magasin-nf-delete-declaration-rfa/${id}`
  );
}
